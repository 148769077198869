import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import TextField from '@mui/material/TextField';


import LibraryAddIcon from '@mui/icons-material/LibraryAdd';


import Button from '@mui/material/Button';


export default function SelectInputRule(props) {

    const initValue = props.choices && props.choices.length > 0 ? props.choices[0].value : "n/a";

    const [field, setField] = React.useState(props.value ? Object.keys(props.value)[0] : initValue);

    const [saveState, setSaveState] = React.useState(props.value ? false : true);

    const choices = props.choices || [];

    const [valueInput, setValueInput] = React.useState(props.value ? props.value[field] : '');

    const emptyFunction = (val) => { }

    const handleChange = (event) => {
        const valueInputElement = event.target.value;
        setField(valueInputElement);
    };


    const onAdd = props.onAdd || emptyFunction;

    const handleOnAdd = () => {

        setSaveState(!saveState);

        onAdd(field, valueInput);

    }

    return (
        // <Box sx={{ minWidth: 260 }}>
        <Box sx={{ marginBottom: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Field</InputLabel>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Select
                            disabled={!saveState}
                            sx={{ width: 200, margin: 1 }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={field}
                            label="Field"
                            onChange={handleChange}
                        >
                            {
                                choices.map((item, index) => (
                                    <MenuItem MenuItem value={item.value} > {item.value}</MenuItem>
                                ))
                            }


                        </Select>

                        <TextField id="outlined-basic" label="rule" variant="outlined" disabled={!saveState}
                            sx={{ width: 300, margin: 1 }}
                            value={valueInput}
                            onChange={(event) => {
                                setValueInput(event.target.value);
                            }}
                        />

                        <Button onClick={handleOnAdd}>

                            <LibraryAddIcon ></LibraryAddIcon>

                        </Button>

                    </Box>
                </FormControl>
            </Box >
        </Box >
    );
}