import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from "react";

import MultiSelectComponent from '../MultiSelectComponent/MultiSelectComponent';

import SaveIcon from '@mui/icons-material/Save';

import { sendCreateRequest } from "./GroupsService";
import Checkbox from '@mui/material/Checkbox';

import {
  GetAllClients, GetAllCmds
} from "../../Shared/HttpRequests";



export default function CreateGroupsComponent(props) {

  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");

  const [isActive, setIsActive] = React.useState(false);

  const [dataClients, setDataClients] = useState([]);
  const [cmds, setCmds] = useState([]);

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const updateDataClients = (event) => {
    console.log("Update from CreateGroupsComponent , event : ", event);
    const arr = event.map(el => el.value);
    // console.log("updateDataClients : arr : ", arr);
    setDataClients(arr);
    // console.log("clients : ", cmds);
  };

  const updateDataCmds = (event) => {
    console.log("Update from CreateGroupsComponent , event : ", event);
    const arr = event.map(el => el.value);
    // console.log("updateDataCmds : arr : ", arr);
    setCmds(arr);
    // console.log("cmds : ", cmds);
  };

  const onAdd = () => {
    const dataObj = {
      name: groupName,
      description,
      active: isActive,
      clients_id: dataClients,
      cmd_id: cmds
    };

    // window.location.reload();

    sendCreateRequest(dataObj).then(
      response => {
        console.log('Groups : Response:', response);
      })
      .catch(error => {
        console.log('Groups : Error:', error);
      }).finally(() => {
        window.location.reload();
      });

  }

  const [clientsOptions, setClientsOptions] = useState([]);
  const [cmdsOptions, setCmdsOptions] = useState([]);

  // const [loadingClients, setLoadingClients] = useState(true);
  // const [loadingCommands, setLoadingCommands] = useState(true);
  const [error, setError] = useState(null);

  const transformToOptionObjs = (datalist) => {
    if (!datalist || datalist.length <= 0) {
      return [];
    }

    return datalist.map(el => { return { label: el.name, value: el.id } });
  }


  React.useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsData = await GetAllClients();
        const clientsDataOptions = transformToOptionObjs(clientsData);

        setClientsOptions(clientsDataOptions);
      } catch (err) {
        setError(err.message);
      } finally {
        // setLoadingClients(false);
      }
    };

    const fetchCommands = async () => {
      try {
        const commandsData = await GetAllCmds();
        const commandsDataOptions = transformToOptionObjs(commandsData);

        setCmdsOptions(commandsDataOptions);
      } catch (err) {
        setError(err.message);
      } finally {
        // setLoadingCommands(false);
      }
    };

    fetchClients();
    fetchCommands();
  }, []);


  return (
    <Card sx={{ width: 800 }}>
      <Box sx={{ width: '100%', padding: 4 }}  >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: "center" }} >
              <h2> <span style={{ background: 'rgb(150 255 232 / 30%)', borderRadius: 12 }}>C</span>reate Group</h2>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: "left", marginLeft: 10 }}  >

              <h4 > Active : </h4>
              <Checkbox
                defaultChecked={isActive}
                onChange={() => setIsActive(!isActive)}
              ></Checkbox>

            </Box>
          </Grid>

          {/* <Grid item xs={5}>
          </Grid> */}

          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>

              <Button onClick={onAdd}> <SaveIcon /> </Button>
            </Box>

          </Grid>





          <Grid item xs={6}>
            <Box  >
              <Box sx={{ marginLeft: 4 }}>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
                <h4 > Name  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <TextField
                  placeholder="Name"
                  sx={{ width: 300 }}
                  onChange={handleGroupNameChange}
                  value={groupName}
                />
              </Box>
            </Box >

          </Grid>


          <Grid item xs={6}>
            <Box >
              {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
              <Box sx={{ marginLeft: 4 }}>
                <h4 > Description  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                <TextField
                  placeholder="Description"
                  sx={{ width: 300 }}
                  onChange={handleDescriptionChange}
                  value={description}
                />

              </Box>
            </Box >

          </Grid>



          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <MultiSelectComponent updateData={updateDataClients} title={"Hosts"} options={clientsOptions} ></MultiSelectComponent>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <MultiSelectComponent updateData={updateDataCmds} title={"Rules"} options={cmdsOptions} ></MultiSelectComponent>
          </Grid>

          {/* 

          <Grid item xs={12}>
            <Button onClick={onAdd}> <SaveIcon /> </Button>
          </Grid> */}

        </Grid>
      </Box>
    </Card>
  );
}