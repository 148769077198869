import axios from 'axios';
import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { GetHeadersContentAuth } from '../../Shared/AuthShared';


const sendCreateRequest = (postGroup) => {

    console.log(" postGroup : ", postGroup);

    // Configuring the request
    const config = {
        headers: GetHeadersContentAuth()
    };

    // URL of the API endpoint
    const url = `${LeaBaseUrl}/api/client_groups`;

    // Sending the POST request
    return axios.post(url, postGroup, config)
        .then(response => {
            console.log('Response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            return Promise.reject(error);
        });
}

const sendEditRequest = (patchGroup) => {

    console.log(" patchGroup : ", patchGroup);

    const id = patchGroup.id;

    // Configuring the request
    const config = {
        headers: GetHeadersContentAuth()
    };

    // URL of the API endpoint
    const url = `${LeaBaseUrl}/api/client_groups?id=eq.${id}`;

    // Sending the Patch request
    return axios.patch(url, patchGroup, config)
        .then(response => {
            console.log('Response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            return Promise.reject(error);
        });
}

const sendDeleteRequest = (deleteGroup) => {

    console.log(" deleteGroup : ", deleteGroup);

    const id = deleteGroup.id;


    // Configuring the request
    const config = {
        headers: GetHeadersContentAuth()
    };

    // URL of the API endpoint
    const url = `${LeaBaseUrl}/api/client_groups?id=eq.${id}`;

    // Sending the Patch request
    return axios.delete(url, config)
        .then(response => {
            console.log('Response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            return Promise.reject(error);
        });
}

export { sendCreateRequest };
export { sendEditRequest };
export { sendDeleteRequest };
