
import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { AuthAndCountHeaders, GetDataAndCountResponse } from '../../Shared/UtillsJsFunctions';
import axios from 'axios';


const GetRulesSorting = async (sortField, orderAsc = true, offset = 0, limit = 100) => {

    const sortOrder = orderAsc ? "asc" : "desc";
    const url = `${LeaBaseUrl}/api/cmds_with_verification?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;
    // const url = `${LeaBaseUrl}/api/clients_verified?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}



export { GetRulesSorting };


