import React from 'react';
import { mySettings } from '../../Shared/MySettings';



import Box from '@mui/material/Box';


import TextField from '@mui/material/TextField';
import { useState } from "react";



import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import Grid from '@mui/material/Grid';



import { isPositiveWholeNumber } from "../../Shared/UtillsJsFunctions";


import { UpdateSettings } from "./SettingsRequests";
import { GetSettingsUi } from '../../Shared/HttpRequests';

import { notActiveBarColor } from '../../Shared/ThemesColor';



const colorsRed = notActiveBarColor;
const colorsYellow = "gold";
const colorsGray = "#CCC";


function BasicCard() {

  const defualtColor = colorsRed;


  const [clientInactiveStatusColor, setInactiveClientStatusColor] = React.useState(defualtColor);
  const [clientInactiveTime, setClientInactiveTime] = useState(14);


  const [executionInactiveStatusColor, setInactiveExecutionStatusColor] = React.useState(defualtColor);
  const [executionInactiveTime, setExecutionInactiveTime] = useState(14);



  const [stdoutShortenNumber, setStdoutShortenNumber] = useState(12);
  const [stderrShortenNumber, setStderrShortenNumber] = useState(12);



  const [numberRowsInExecution, setNumberRowsInExecution] = useState(200);
  // const [errorOnlyNumbers, setErrorOnlyNumbers] = React.useState(false);


  const [settingsLoaded, setSettingsLoaded] = useState(false); // New state to track settings loading


  const [textToSend, setTextToSend] = React.useState("");


  React.useEffect(() => {
    const initializeSettings = async () => {
      try {
        const settings_ui_Obj_data_list = await GetSettingsUi();

        // console.log(" hey im here before the fall");
        // console.log(" settings_ui_Obj_data_list.length ", settings_ui_Obj_data_list.length);

        const settings_ui_Obj = settings_ui_Obj_data_list[0].settings;

        // console.log("settings_ui_Obj", settings_ui_Obj);

        setInactiveClientStatusColor(settings_ui_Obj.client_inactive_status_color || clientInactiveStatusColor);
        setClientInactiveTime(settings_ui_Obj.client_inactive_time || clientInactiveTime);

        setInactiveExecutionStatusColor(settings_ui_Obj.execution_inactive_status_color || executionInactiveStatusColor);
        setExecutionInactiveTime(settings_ui_Obj.execution_inactive_time || executionInactiveTime);

        setStdoutShortenNumber(settings_ui_Obj.stdout_shorten_number || stdoutShortenNumber);
        setStderrShortenNumber(settings_ui_Obj.stderr_shorten_number || stderrShortenNumber);

        setNumberRowsInExecution(settings_ui_Obj.number_rows_execution || numberRowsInExecution);



        setSettingsLoaded(true); // Mark settings as loaded
      } catch (error) {
        console.error("Error fetching settings data:", error);
      }
    };
    initializeSettings();
  }, []); // Runs only once on component mount




  const handleClientInactiveStatusColor = (event) => {
    setInactiveClientStatusColor(event.target.value);
  };

  const handleClientInactiveTime = (event) => {

    const inputTxt = event.target.value;

    if (isPositiveWholeNumber(inputTxt)) {
      setClientInactiveTime(inputTxt);
      // const checkIfEmpty = inputTxt.trim() === "";
    }
  };

  const handleExecutionInactiveStatusColor = (event) => {
    setInactiveExecutionStatusColor(event.target.value);
  };



  const handleExecutionInactiveTime = (event) => {

    const inputTxt = event.target.value;

    if (isPositiveWholeNumber(inputTxt)) {
      setExecutionInactiveTime(inputTxt);
    }
  };

  const handleNumberRowsInExecution = (event) => {
    const inputTxt = event.target.value;

    if (isPositiveWholeNumber(inputTxt)) {
      setNumberRowsInExecution(inputTxt);
    }
  }

  const handleStdoutShortenNumber = (event) => {
    const inputTxt = event.target.value;

    if (isPositiveWholeNumber(inputTxt)) {
      setStdoutShortenNumber(inputTxt);
    }
  }

  const handleStderrShortenNumber = (event) => {
    const inputTxt = event.target.value;

    if (isPositiveWholeNumber(inputTxt)) {
      setStderrShortenNumber(inputTxt);
    }
  }

  const handleSaveSettings = async () => {


    const settingsObj = {

      client_inactive_status_color: clientInactiveStatusColor,
      client_inactive_time: clientInactiveTime,

      execution_inactive_status_color: executionInactiveStatusColor,
      execution_inactive_time: executionInactiveTime,

      stdout_shorten_number: stdoutShortenNumber,
      stderr_shorten_number: stderrShortenNumber,


      number_rows_execution: numberRowsInExecution,
    }

    const settingObjString = JSON.stringify(settingsObj, null, 3);

    setTextToSend(settingObjString);


    try {


      // const updatedAnswer = await UpdateSettings(settingObjString);
      const updatedAnswer = await UpdateSettings(settingsObj);

      console.log(" updatedAnswer ", updatedAnswer);

    }
    catch (error) {

      console.error("Error updated settings : ", error);
    }


  }

  const sizeLabelSpace = 4;
  const sizeFieldSpace = 12 - sizeLabelSpace;

  const sizeFonts = "small";

  // colorsRed, colorsYellow , colorsGray


  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", marginBottom: 6 }}>
          <Typography variant="h3" component="div" sx={{ fontWeight: "bold" }}>
            Settings
          </Typography>
        </Box>

        {/* <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", margin: 2 }}> */}
        <Box sx={{ margin: 6 }}>

          <Box sx={{ width: 700 }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >

              {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography>
          <br />

        <Typography variant="body2">
          well meaning and kindly.
          <br />
          <br />
          {'"a benevolent smile"'}
        </Typography> */}




              <Grid item xs={sizeLabelSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Client Inactive Status Color  </p>
                </Box>
              </Grid>


              <Grid item xs={sizeFieldSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>

                  {/* <BasicSelect></BasicSelect> */}

                  <Box sx={{ width: 196 }}>
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Color</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={clientInactiveStatusColor}
                        label="Color"
                        onChange={handleClientInactiveStatusColor}
                        size="small"

                        sx={{ fontSize: sizeFonts, height: 40 }}
                      >
                        {/* <MenuItem value={colorsRed} > Red <span> {"  ■"} </span> </MenuItem> */}
                        <MenuItem value={colorsRed} > Red </MenuItem>
                        <MenuItem value={colorsGray}>Gray</MenuItem>
                        <MenuItem value={colorsYellow}>Yellow</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={sizeLabelSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Client Inactive Time ( Days )  </p>
                </Box>
              </Grid>


              <Grid item xs={sizeFieldSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box sx={{ width: 200 }}>
                  {/* <BasicSelect></BasicSelect> */}
                  <TextField id="outlined-basic" label="Days" variant="outlined"
                    placeholder="Name"
                    onChange={handleClientInactiveTime}
                    value={clientInactiveTime}
                    size="small"
                    sx={{ fontSize: sizeFonts }}

                  />
                </Box>
              </Grid>



              <Grid item xs={sizeLabelSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Execute Inactive Status Color  </p>
                </Box>
              </Grid>


              <Grid item xs={sizeFieldSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>

                  {/* <BasicSelect></BasicSelect> */}

                  <Box sx={{ width: 196 }}>
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Color</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={executionInactiveStatusColor}
                        label="Color"
                        onChange={handleExecutionInactiveStatusColor}
                        size="small"

                        sx={{ fontSize: sizeFonts, height: 40 }}
                      >
                        <MenuItem value={colorsRed}  >Red</MenuItem>
                        <MenuItem value={colorsGray} >Gray</MenuItem>
                        <MenuItem value={colorsYellow} >Yellow</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>


              <Grid item xs={sizeLabelSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Execute Inactive Time ( Days )  </p>
                </Box>
              </Grid>


              <Grid item xs={sizeFieldSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box sx={{ width: 200 }}>
                  {/* <BasicSelect></BasicSelect> */}
                  <TextField id="outlined-basic" label="Days" variant="outlined"
                    placeholder="Name"
                    onChange={handleExecutionInactiveTime}
                    value={executionInactiveTime}
                    size="small"
                    sx={{ fontSize: sizeFonts }}

                  />

                </Box>

              </Grid>

              {/*  */}

              <Grid item xs={sizeLabelSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Character Display Stdout  </p>
                </Box>
              </Grid>


              <Grid item xs={sizeFieldSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box sx={{ width: 200 }}>
                  {/* <BasicSelect></BasicSelect> */}
                  <TextField id="outlined-basic" label="Days" variant="outlined"
                    placeholder="Name"
                    onChange={handleStdoutShortenNumber}
                    value={stdoutShortenNumber}
                    size="small"
                    sx={{ fontSize: sizeFonts }}

                  />

                </Box>

              </Grid>



              <Grid item xs={sizeLabelSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Character Display Stderr  </p>
                </Box>
              </Grid>


              <Grid item xs={sizeFieldSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box sx={{ width: 200 }}>
                  {/* <BasicSelect></BasicSelect> */}
                  <TextField id="outlined-basic" label="Days" variant="outlined"
                    placeholder="Name"
                    onChange={handleStderrShortenNumber}
                    value={stderrShortenNumber}
                    size="small"
                    sx={{ fontSize: sizeFonts }}

                  />

                </Box>

              </Grid>



              <Grid item xs={sizeLabelSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Number Of Rows In Execution  </p>
                </Box>
              </Grid>


              <Grid item xs={sizeFieldSpace} >
                {/* <Box sx={{ display: 'flex', justifyContent: 'right' }}> */}
                <Box sx={{ width: 200 }}>
                  {/* <BasicSelect></BasicSelect> */}
                  <TextField id="outlined-basic" label="Days" variant="outlined"
                    placeholder="Name"
                    onChange={handleNumberRowsInExecution}
                    value={numberRowsInExecution}
                    size="small"
                    sx={{ fontSize: sizeFonts }}

                  />

                </Box>

              </Grid>




            </Grid>
          </Box>
        </Box>

      </CardContent>

      <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
        <CardActions>
          <Button size="" sx={{ width: "400px" }} variant="contained" onClick={handleSaveSettings}>Save</Button>
        </CardActions>

      </Box>


      {/* 
      <p style={{ textAlign: 'center', margin: '0 auto', width: '700px', marginTop: 12, marginBottom: 12 }}>
        {textToSend}
      </p> */}


    </Card>
  );
}




export default function Settings() {
  return (<>

    <BasicCard></BasicCard>

  </>
  )
}







// {/* <Box sx={{ display: 'flex', justifyContent: 'center', align: 'center' }} >
// <h2 style={{ align: 'center' }} >Settings: </h2>

// <br />
// {/* <br /> */}

// <TextField
//   placeholder="Category"
//   sx={{ width: 300 }}
//   onChange={(e) => handleExecutionRowsPerRule(e.target.value)}
//   value={category}
// />
// </Box>


// <button onClick={handleDisplayOnClick}> Display Setting Obj</button>

// <Box>
// <p>
//   {settingDisplay}
// </p>
// </Box> */}






// {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} >
// <TextField
//   placeholder="Category"
//   sx={{ width: 300 }}
//   onChange={(e) => setCategory(e.target.value)}
//   value={category}
// />
// </Box> */}