import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

const PageNotFound = () => {

    const nav = useNavigate();

    const navigateToHomePage = () => 
    {
        nav('/');
    }

    return (
        <div>
            <h2>Page Not Found</h2>
            <h4> - error - </h4>
            <Button onClick={navigateToHomePage}> Home</Button>
        </div>
    );
};

export default PageNotFound;