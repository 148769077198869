

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useState } from "react";


import { convertToTitleCase } from "../../Shared/UtillsJsFunctions";

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';


import SelectInputRule from './SelectInputRule';
import SelectedDisplayRule from './SelectedDisplayRule';





export default function RuleFieldComponent(props) {

    const topLevelField = props.toplevelField;

    const [newData, setNewData] = useState(false);

    const options = props.options || [];

    const preLoadData = props.preLoadData || [];

    const emptyFunction = () => { };

    const handleDeleteRule = props.handleDeleteRule || emptyFunction;
    const handleAddRule = props.handleAddRule || emptyFunction;

    const generatedKey = props.generatedKey || emptyFunction;



    return (<>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginTop: 0, marginLeft: 4 }} >

            {/* <Button size="medium" color='primary' > */}
            <IconButton variant="outlined" size="small" color='primary' sx={{ padding: 2 }} onClick={() => { setNewData(true) }} disabled={newData} >
                {/* <AddCircleIcon ></AddCircleIcon> */}
                <AddIcon size="medium" ></AddIcon>
            </IconButton>
            {/* </Button> */}

            <Box sx={{ marginLeft: 1 }}>
                <h4>
                    {convertToTitleCase(topLevelField)}
                </h4>
            </Box>
        </Grid >

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >

            <Box sx={{ display: 'flex', flexDirection: 'column' }} >

                {preLoadData.map((el, index, topLevelField) => (

                    <SelectedDisplayRule choices={options}
                        key={generatedKey(el, index, topLevelField)}

                        onDelete={handleDeleteRule}
                        value={el}
                        disabled={true}
                    ></SelectedDisplayRule>

                ))}


                {newData ?

                    <SelectInputRule key={preLoadData.length} choices={options}
                        onAdd={(jsonFieldName, jsonFieldVal) => { setNewData(false); handleAddRule(jsonFieldName, jsonFieldVal); }}
                    ></SelectInputRule>

                    : null

                }

            </Box>

        </Grid >
    </>

    );
}







//. fieldLabel