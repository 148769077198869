import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  Drawer,
  Box,
  List,
  ListItemText,
  ListItemButton,
  Typography,
  ListItemIcon,
  CssBaseline,
  Toolbar,
  Divider,
} from "@mui/material";

import {
  Dashboard as DashboardIcon,
  Assignment as CompliancyIcon,
  Build as ExecutionIcon,
  LibraryBooks as RulesIcon,
  Group as GroupsIcon,
  Extension as ModulesIcon,
  Notifications as NotificationsIcon,
  Person as UsersIcon,
  SupervisorAccount as RolesIcon,
  Settings as SettingsIcon,
  HelpOutline as FAQIcon,
  ExitToApp as LogoutIcon,
} from "@mui/icons-material";




import { activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';

import { GetLastestsResults } from './sideBarResuqest';
import Badge from '@mui/material/Badge';
import { useRef } from 'react';


// import { ExecutionWithDotBadge } from './ExecutionsWithDotBadge';
import { ExecutionWithDotBadge } from './ExecutionsWithDotBadge';






// const myMap = new Map();

// function generateKey(client_id, cmd_id) {
//   return `${client_id}_${cmd_id}`;
// }


// const ExecutionWithDotBadge = () => {

//   const [invisible, setInvisible] = React.useState(false);

//   // const myMap = new Map();

//   const handleBadgeVisibility = () => {
//     // setInvisible(!invisible);
//     console.log(" here make a call  , invisible : ", invisible);
//     console.log(" here make a call  , counter : ", counter);


//     invisibleRef.current = !invisibleRef.current;
//     setInvisible(invisibleRef.current);

//   };

//   const setInvisibleAndRef = (invisibleEl) => {
//     // setInvisibleAndRef();

//     invisibleRef.current = invisibleEl;
//     setInvisible(invisibleRef.current);
//     // setInvisible(invisibleEl);

//     console.log("  ====> ");
//   }

//   // let counter = 0;

//   const timerFunc = () => {
//     // console.log(" -> timer call  ", counter);
//     // console.log(" here make a call  , invisible : ", invisible);
//     handleBadgeVisibility();
//     // counter++;
//   }

//   const apiChecksCall = async () => {

//     console.log(" -> ping apiChecksCall ");

//     try {

//       const respData = await GetLastestsResults();

//       const data = respData;

//       let flag = false;

//       data.forEach(item => {

//         const key = generateKey(item.clients_id, item.cmds_id);
//         const mapValue = myMap.get(key);

//         if (mapValue !== undefined) {
//           // Key exists in the map
//           if (mapValue === true && item.verified === false) {
//             flag = true;
//           }
//         } else {
//           // Key does not exist in the map
//           if (item.verified === false) {
//             flag = true;
//           }
//         }

//         // Optionally, you can update the map with the new value
//         myMap.set(key, item.value);

//       });

//       console.log("Flag:", flag);

//       if (flag) {
//         setInvisible(false);
//       }


//     } catch (error) {
//       console.log(error);
//     }
//   }

//   // // const interval = 5_000; // milliseconds

//   // // // Call myFunction every 5 seconds (5000 milliseconds)
//   // // setInterval(timerFunc, interval);

//   const [counter, setCounter] = useState(0); // This will trigger re-renders
//   const counterRef = useRef(0); // This will hold the counter without triggering re-renders
//   const intervalRef = useRef(null); // To store the interval reference
//   const invisibleRef = useRef(false); // To store the interval reference

//   useEffect(() => {
//     // Function to increment the counter reference
//     const incrementCounter = () => {
//       counterRef.current += 1; // Increment the counter in the ref
//       console.log("Timer tick:", counterRef.current);
//       setCounter(counterRef.current); // Update the state with the new counter value

//       // apiChecksCall();



//       try {

//         const activeApiCall = async () => {
//           const respData = await GetLastestsResults();
//           const data = respData;

//           console.log(" data ", data);
//           console.log(" counter :  ", counter);

//           // timerFunc();
//           // handleBadgeVisibility();

//           // invisibleRef.current = !invisibleRef.current;
//           // setInvisible(invisibleRef.current);

//           // handleBadgeVisibility();



//           if (counterRef.current) {
//             setInvisibleAndRef(true);
//             // setInvisible(true);
//           } else {
//             setInvisibleAndRef(false);
//             // setInvisible(false);
//           }

//           console.log("  invisibleRef.current : ", invisibleRef.current);

//         }

//         activeApiCall();

//       }
//       catch (error) {

//         console.log("error : ", error);
//       }

//       // };

//     };

//     // Start interval only if it doesn't exist
//     if (!intervalRef.current) {
//       intervalRef.current = setInterval(incrementCounter, 5000); // Increment every 5 seconds
//     }

//     // Cleanup interval on component unmount
//     return () => {
//       if (intervalRef.current) {
//         clearInterval(intervalRef.current); // Clear the interval
//         intervalRef.current = null; // Reset the interval reference
//       }
//     };


//   }, []); // Empty dependency array ensures this only runs once on mount


//   // apiChecksCall();
//   // setInterval(apiChecksCall, 20_000);

//   return (
//     <Box sx={{ color: 'action.active' }}>
//       <Badge color="primary" variant="dot" invisible={invisible} >
//         <ExecutionIcon />

//       </Badge>

//       <p>Counter: {counter}</p> {/* Display the counter */}
//     </Box>
//   )
// }

// sx={{ color : ''}}




const sidebarNavigations = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    path: "/compliancy",
    name: "Compliancy",
    icon: <CompliancyIcon />,
  },
  {
    path: "/execution",
    name: "Execution",
    icon: <ExecutionIcon/>,
    // icon: <ExecutionWithDotBadge />,
  },
  {
    path: "/rules",
    name: "Rules",
    icon: <RulesIcon />,
  },
  {
    path: "/groups",
    name: "Groups",
    icon: <GroupsIcon />,
  },
  {
    path: "/users",
    name: "Users",
    icon: <UsersIcon />,
  },
  {
    path: "/roles",
    name: "Roles",
    icon: <RolesIcon />,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <SettingsIcon />,
  },
  {
    path: "/legal",
    name: "Legal",
    icon: <FAQIcon />,
  },
  {
    path: "/logout",
    name: "Logout",
    icon: <LogoutIcon />,
  },
];

const drawerWidth = "0vw";


export default function Sidebar({
  onLogOut,
  isLoggedIn,
  isOpen,
  drawerToggle,
}) {
  const nav = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  // console.log(" --- pathname ", pathname);


  const handleNavigation = (el, path) => {
    if (path === "/logout") {
      onLogOut();
      nav("/login");
    } else {
      // drawerToggle();
      // console.log("~~ el", el);
      // console.log("~~ path", path);

      // el.value = `${el.value} >>`;



      nav(path);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexShrink: 0,
        flexDirection: "column",
        height: "10%",
        width: { xs: isOpen ? drawerWidth : 0 },
        margin: 0, padding: 0
      }}
    >
      <CssBaseline />
      {isLoggedIn && (
        <>
          <Toolbar >
            <Drawer
              open={isOpen}
              onClose={drawerToggle}
              anchor="left"
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { boxSizing: "border-box" },

                backgroundColor: "red",

                margin: 2, paddingTop: 7

              }}


            >
              <Box m={2} pt={7}>
                <List>
                  {sidebarNavigations.map((item, index) => (
                    <ListItemButton
                      key={index}
                      component={Link}
                      to={item.path}
                      onClick={(el) => handleNavigation(el, item.path)}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#CCC",

                          // background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)',

                          "& .MuiListItemIcon-root": { // Target the ListItemIcon component inside the button
                            color: "#FFF",
                          },



                          // backgroundColor: activeBarColor,
                          // backgroundColor: "#FF96AD !important",

                          // backgroundColor: "#CCC !important",

                          // background: "#CCC",
                          // color: "#FFF"



                        },

                        borderRadius: "1rem",
                        // "border-radius": "1rem",
                        marginBottom: "0.1rem",
                        paddingRight: "2rem",

                        background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)',
                        color: "#FFF",


                        background: pathname === item.path ? activeBarColor : "",
                        color: pathname === item.path ? "#000" : "",




                        // ...ColoringBackgroudTab(item.path),
                        //backgroundColor: activeBarColor

                      }}
                    >
                      <ListItemIcon sx={{
                        // "&:hover": {
                        //   color: "#FFF"
                        // },
                        color: pathname === item.path ? "#000" : "",
                      }}
                      >{item.icon}</ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItemButton>

                  ))}
                </List>
              </Box>
            </Drawer>
          </Toolbar>
        </>
      )
      }
    </Box >
  );
}
