import { ConstructionOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

// sx={{ overflow : 'auto' }}

const Example = (props) => {

  const initSelected = props.initSelected || [];
  const [selected, setSelected] = useState(initSelected);

  const handleClearSelection = () => {

    setSelected([]);

    console.log(" Hey from -> handleClearSelection -> ");

  };

  // props.handleClearSelection = handleClearSelection;

  // props.handleClearSelectionPass(handleClearSelection);


  const options = props.options || [];


  console.log("str ? : ", props.handleClearSelection);

  console.log("tryField ? : ", props.tryField);

  console.log(" props.options  :::: ", props.options);

  console.log(" PROPS : ", props);

  // props.handleClearSelection = handleClearSelection; // = handleClearSelection;


  return (
    <div style={{ width: 300, height: 400 }}  >

      <h4> {props.title} </h4>

      {/* <pre>{JSON.stringify(selected)}</pre> */}
      {/* <MultiSelect style={{ height : 100}} */}
      <MultiSelect style={{ overflow: 'auto' }}
        options={options}
        value={selected}

        // value={[]}

        onChange={(event) => { props.updateData(event); setSelected(event); }}
        // onChange={setSelected}
        labelledBy="Select"
      // shouldToggleOnHover={true}

      />

    </div>
  );
};

export default Example;