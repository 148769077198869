import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState, useRef } from "react";

import SaveIcon from '@mui/icons-material/Save';

import { sendEditRequest, sendDeleteRequest } from "./RulesService";
import Checkbox from '@mui/material/Checkbox';

import DeleteIcon from '@mui/icons-material/Delete';


import { JsonEditor } from 'json-edit-react';

import { convertToTitleCase } from "../../Shared/UtillsJsFunctions";
import { isPositiveWholeNumber } from "../../Shared/UtillsJsFunctions";


import RuleFieldComponent from "./RuleFieldComponent";



import MultiSelectComponent from '../MultiSelectComponent/MultiSelectComponent';


import { GetAllClients } from '../../Shared/HttpRequests';


import RuleFieldPickedComponent from './RuleFieldPickedComponent';


export default function EditRuleComponent(props) {

    console.log(" EditRuleComponent : PROPS :  ", props);


    const ruleId = props.ruleId || "not have an id";

    const initRuleName = props.ruleName || "";
    const initDescription = props.description || "";

    const initIsActive = props.isActive || false;

    const initRule = props.rule || {};
    const initCategory = props.category || "";

    const initInterval = props.interval || "";
    const initRuleTemplate = props.ruleTemplate || "";



    const [ruleName, setRuleName] = useState(initRuleName);
    const [description, setDescription] = useState(initDescription);

    const [isActive, setIsActive] = React.useState(initIsActive);

    const [category, setCategory] = useState(initCategory);

    const [interval, setInterval] = useState(initInterval);
    const [ruleTemplate, setRuleTemplate] = useState(initRuleTemplate);


    const [jsonObj, setJsonObj] = useState(initRule);

    // const 

    // info.dist.name
    // info.uname.system


    // cmdsOptions.current = commandsData.map((el) => { return { label: el.name, value: el.id } });





    console.log("initRule", initRule);

    const onEdit = () => {

        // const cmdRuleFromJson = `"${JSON.stringify(jsonObj)}"`;
        const cmdRuleFromJson = jsonObj;
        const intervalFormatted = interval !== "" ? +interval : null;
        const ruleTemplateFormatted = ruleTemplate !== "" ? +ruleTemplate : null;


        const dataObj = {
            name: ruleName,
            description: description,
            active: isActive,
            cmd: cmdRuleFromJson,
            category: category || "general",
            cmd_templates: ruleTemplateFormatted,
            cycle: intervalFormatted,
            id: ruleId,
        };


        sendEditRequest(dataObj).then(
            response => {
                console.log("[Edit] : dataObj : ", dataObj);
                console.log('Rule : Response:', response);
            })
            .catch(error => {
                console.log('Rule : Error:', error);
            }).finally(() => {
                window.location.reload();
            });
    }

    const onDelete = () => {

        const cmdRuleFromJson = `"${JSON.stringify(jsonObj)}"`;


        const dataObj = {
            name: ruleName,
            description: description,
            active: isActive,
            cmd: cmdRuleFromJson,
            category: category || "general",
            cmd_templates: ruleTemplate,
            cycle: interval,
            id: ruleId,

        };

        sendDeleteRequest(dataObj).then(
            response => {
                console.log("[Delete] : dataObj : ", dataObj);
                console.log('Rule : Response:', response);
            })
            .catch(error => {
                console.log('Rule : Error:', error);
            }).finally(() => {
                window.location.reload();
            });
    }



    const createHandlerFunctionAdd = (topLevelField) => {

        return (jsonFieldName, jsonFieldVal) => {

            const newJsonObj = { ...jsonObj };

            // const topLevelField = "validation";

            if (!jsonFieldVal) {
                console.log("Can't be empty")
                return;
            }


            if (!newJsonObj[topLevelField]) {
                newJsonObj[topLevelField] = [];
            }

            if (jsonFieldName === "re : stdout" || jsonFieldName === "re : stderr") {
                let fieldName = "stdout";
                const regexField = "re";


                if (jsonFieldName === "re : stdout") {
                    fieldName = "stdout";
                } else if (jsonFieldName === "re : stderr") {
                    fieldName = "stderr";
                }

                newJsonObj[topLevelField].push({ [regexField]: { [fieldName]: jsonFieldVal } });
            } else {
                newJsonObj[topLevelField].push({ [jsonFieldName]: jsonFieldVal });
            }


            if (dists && dists.length > 0) {

                // newJsonObj[topLevelField][jsonFieldName].push({ ["dists"]: dists });

                // newJsonObj[topLevelField].push({ ["dists"]: dists });

                const lastIndex = newJsonObj[topLevelField].length - 1;

                // newJsonObj[topLevelField][lastIndex].assign({ ["dists"]: dists });

                Object.assign(newJsonObj[topLevelField][lastIndex], { ["distribution"]: dists });

            }


            if (systems && systems.length > 0) {

                // newJsonObj[topLevelField][jsonFieldName].push({ ["dists"]: dists });

                // newJsonObj[topLevelField].push({ ["dists"]: dists });

                const lastIndex = newJsonObj[topLevelField].length - 1;

                // newJsonObj[topLevelField][lastIndex].assign({ ["dists"]: dists });

                Object.assign(newJsonObj[topLevelField][lastIndex], { ["system"]: systems });

            }

            setJsonObj(newJsonObj);

            // console.log("newJsonObj", newJsonObj);

        }
    }

    const createHandlerFunctionDelete = (topLevelField) => {

        return (jsonFieldName, jsonFieldVal) => {

            const newJsonObj = { ...jsonObj };

            // const topLevelField = "validation";

            const index = newJsonObj[topLevelField].findIndex(el => el[jsonFieldName] === jsonFieldVal);

            if (index > -1) { // only splice array when item is found
                newJsonObj[topLevelField].splice(index, 1); // 2nd parameter means remove one item only
            }

            setJsonObj(newJsonObj);


        }
    }

    const tableFieldKindNameValidation = "validation";
    // const handleAddedValidationRule = createHandlerFunctionAdd(tableFieldKindNameValidation, tableFieldNameValidation);
    // const handleDeleteValidationRule = createHandlerFunctionDelete(tableFieldKindNameValidation, tableFieldNameValidation);


    const handleAddedValidationRule = createHandlerFunctionAdd(tableFieldKindNameValidation);
    const handleDeleteValidationRule = createHandlerFunctionDelete(tableFieldKindNameValidation);


    const tableFieldKindNameExepct = "expect";
    const handleAddedExpect = createHandlerFunctionAdd(tableFieldKindNameExepct);
    const handleDeleteExpect = createHandlerFunctionDelete(tableFieldKindNameExepct);

    const tableFieldKindNameRemedy = "remedy";
    const handleAddedRemedy = createHandlerFunctionAdd(tableFieldKindNameRemedy);
    const handleDeleteRemedy = createHandlerFunctionDelete(tableFieldKindNameRemedy);


    const optionsFieldValidation = [
        { "value": "command" },
        { "value": "Base64" },
        { "value": "uri_sh" },
        { "value": "function" },
    ];

    const optionsFieldExepct = [
        { "value": "re : stdout" },
        { "value": "re : stderr" },
        { "value": "ReturnCode" },
        { "value": "Md5" },
    ];

    const optionsFieldRemedy = [
        { "value": "command" },
        { "value": "Base64" },
        { "value": "uri_sh" },
        { "value": "function" },
    ];

    const preLoadDataValidation = jsonObj[tableFieldKindNameValidation];
    const preLoadDataExepct = jsonObj[tableFieldKindNameExepct];
    const preLoadDataRemedy = jsonObj[tableFieldKindNameRemedy];

    const generatedKey = (el, index, topLevelField) => {
        const fieldKey = Object.keys(el)[0];
        const generatedKey = `${fieldKey}${el[fieldKey]}${index}${topLevelField}`;

        // console.log(" generateKey ", generatedKey);
        return generatedKey;
    }


    const [dists, setDists] = useState([]);
    const [systems, setSystems] = useState([]);


    const updateDists = (event) => {
        console.log("Update from updateDists , event : ", event);
        const arr = event.map(el => el.label.toLowerCase());
        setDists(arr);

        const fieldName = "distribution";
        const newJsonObj = { ...jsonObj };

        // newJsonObj["validation"][fieldName] = arr;

        // newJsonObj[fieldName] = arr;

        // newJsonObj["remedy"][fieldName] = arr;

        setJsonObj(newJsonObj);

    };



    const updateSystems = (event) => {
        console.log("Update from updateSystems , event : ", event);
        const arr = event.map(el => el.label.toLowerCase());
        setSystems(arr);

        const fieldName = "system";
        const newJsonObj = { ...jsonObj };

        newJsonObj[fieldName] = arr;
        // newJsonObj["remedy"][fieldName] = arr;

        // newJsonObj["validation"][fieldName] = arr;
        // newJsonObj["remedy"][fieldName] = arr;

        setJsonObj(newJsonObj);


    };


    const [distOptions, setDistOptions] = useState([]);
    const [systemsOptions, setSystemsOptions] = useState([]);


    // clientsOptions.current = clientsData.map((el) => { return { label: el.name, value: el.id } });
    // cmdsOptions.current = commandsData.map((el) => { return { label: el.name, value: el.id } });


    const apiCallAndDataTransform = () => {

        GetAllClients().then(response => {

            console.log(` Clients : ${response} `, response)
            // console.log("Here ? : ", response)

            // const transformedDataAry = transformedDataArrAction(response);

            const dataDistName = MakeUniqueArrayField(response.map((el) => { return { label: el.info.dist.name, value: el.id } }), "label");
            const dataSystem = MakeUniqueArrayField(response.map((el) => { return { label: el.info.uname.system, value: el.id } }), "label");

            setDistOptions(dataDistName);
            setSystemsOptions(dataSystem);

        })
            .catch(error => {
                console.error("Error fetching data:", error);
            }).finally(() => {
                // setIsLoading(false);
            });

    }

    React.useEffect(() => {

        // TODO : to make it better when api calls!
        // BM !!
        apiCallAndDataTransform();


    }, []);

    // apiCallAndDataTransform();



    const handleIntervalInput = (e) => {
        const inputTxt = e.target.value;

        if (isPositiveWholeNumber(inputTxt)) {
            setInterval(inputTxt);
            const checkIfEmpty = inputTxt.trim() === "";
            setSaveEnabled(checkIfEmpty);
        }


    }

    const [saveEnabled, setSaveEnabled] = useState(false);




    const MakeUniqueArrayField = (elements, key) => {
        // elements["key"]

        const seen = new Set();
        const ary = [];

        elements.forEach(item => {
            const el = item[key];
            const duplicate = seen.has(el);

            if (!duplicate) {
                console.log(" el : ", el);
                console.log(" item : ", item);

                // ary.push(el);
                ary.push({ "value": item.value, "label": el });
                seen.add(el);

            }
        })

        console.log(" ary : ", ary)

        return ary;
    };





    return (

        <Card sx={{ width: 800 }}>
            <Box sx={{ width: '100%', padding: 4 }}  >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: "center" }} >
                            <h2> {initRuleName} </h2>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box sx={{ display: 'flex', justifyContent: "left", marginLeft: 10 }}  >

                            <h4 > Active : </h4>
                            <Checkbox
                                defaultChecked={isActive}
                                onChange={() => setIsActive(!isActive)}
                            ></Checkbox>

                        </Box>
                    </Grid>

                    {/* <Grid item xs={5}></Grid> */}

                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
                        {/* <Box sx={{ display: 'flex', justifyContent: "center" }} > */}
                        <Box sx={{ display: 'flex' }}>
                            <Button onClick={onEdit} disabled={saveEnabled} > <SaveIcon /> </Button>
                            <Button onClick={onDelete}> <DeleteIcon /> </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box  >
                            <Box sx={{ marginLeft: 4 }}>
                                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
                                <h4 > Name  </h4>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                                <TextField
                                    placeholder="Name"
                                    sx={{ width: 300 }}
                                    onChange={(e) => setRuleName(e.target.value)}
                                    value={ruleName}
                                />
                            </Box>
                        </Box >

                    </Grid>


                    <Grid item xs={6}>
                        <Box >
                            {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
                            <Box sx={{ marginLeft: 4 }}>
                                <h4 > Description  </h4>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                                <TextField
                                    placeholder="Description"
                                    sx={{ width: 300 }}
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={description}
                                />

                            </Box>
                        </Box >

                    </Grid>






                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Box  >
                            <Box >
                                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
                                <h4 > Category  </h4>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                                <TextField
                                    placeholder="Category"
                                    sx={{ width: 300 }}
                                    onChange={(e) => setCategory(e.target.value)}
                                    value={category}
                                />
                            </Box>
                        </Box >
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Box  >
                            <Box >
                                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
                                <h4 > Template  </h4>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                                <TextField
                                    placeholder="Template"
                                    sx={{ width: 300 }}
                                    onChange={(e) => setRuleTemplate(e.target.value)}
                                    value={ruleTemplate}
                                />
                            </Box>
                            <Box> <p> * need to be a number </p> </Box>
                        </Box >
                    </Grid>



                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Box  >
                            <Box >
                                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
                                <h4 > Interval  </h4>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                                <TextField
                                    placeholder="Interval"
                                    sx={{ width: 300 }}
                                    onChange={(e) => handleIntervalInput(e)}
                                    value={interval}
                                />
                            </Box>
                            <Box> <p> * need to be a number </p> </Box>
                        </Box >
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Box  >

                        </Box >
                    </Grid>



                    <Grid item xs={12} sx={{ margin: 2 }} ></Grid>


                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', height: 200 }} >
                        <MultiSelectComponent
                            // updateData={handleUpdateDists}
                            updateData={updateDists}
                            title={"Distribution"}
                            options={distOptions}
                        // initSelected={initSelectedClients}

                        ></MultiSelectComponent>

                        {/* {dists.map((item) =>
                            <p>
                                - {item}
                            </p>
                        )} */}

                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', height: 200 }} >
                        <MultiSelectComponent
                            // updateData={handleUpdateSystems}
                            updateData={updateSystems}
                            title={"System"}
                            options={systemsOptions}
                        // initSelected={initSelectedCmds}

                        ></MultiSelectComponent>

                        {/* {systems.map((item) =>
                            <p>
                                - {item}
                            </p>
                        )} */}

                    </Grid>


                    <RuleFieldPickedComponent
                        toplevelField={tableFieldKindNameValidation}
                        options={optionsFieldValidation}
                        preLoadData={preLoadDataValidation}
                        handleDeleteRule={handleDeleteValidationRule}
                        handleAddRule={handleAddedValidationRule}
                        generatedKey={generatedKey}

                    ></RuleFieldPickedComponent>

                    <RuleFieldPickedComponent
                        toplevelField={tableFieldKindNameExepct}
                        options={optionsFieldExepct}
                        preLoadData={preLoadDataExepct}
                        handleDeleteRule={handleDeleteExpect}
                        handleAddRule={handleAddedExpect}
                        generatedKey={generatedKey}

                    ></RuleFieldPickedComponent>

                    <RuleFieldPickedComponent
                        toplevelField={tableFieldKindNameRemedy}
                        options={optionsFieldRemedy}
                        preLoadData={preLoadDataRemedy}
                        handleDeleteRule={handleDeleteRemedy}
                        handleAddRule={handleAddedRemedy}
                        generatedKey={generatedKey}

                    ></RuleFieldPickedComponent>



                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Box >
                            <Box >
                                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
                                <h4 > Rule Json Editor  </h4>
                            </Box>
                            {/* <Box> <p> json edit </p> </Box> */}
                            <JsonEditor
                                rootName="rule"
                                // rootFontSize={16}
                                minWidth={400}
                                data={jsonObj}
                                onUpdate={({ newData }) => {
                                    setJsonObj(newData);
                                }}
                            />

                            {/* <Box>
                                <p>
                                    {JSON.stringify(jsonObj)}
                                </p>
                                <p>
                                    {JSON.stringify(initRule)}
                                </p>
                            </Box> */}

                        </Box >
                    </Grid>
                </Grid>
            </Box>
        </Card >
    );
}






