
import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { AuthAndCountHeaders, GetDataAndCountResponse } from '../../Shared/UtillsJsFunctions';
import axios from 'axios';



const GetRolesSorting = async (sortField, orderAsc = true, offset = 0, limit = 100) => { // https://51.17.118.208/api/roles?id=in.%281%2C2%29
    const sortOrder = orderAsc ? "asc" : "desc";
    const url = `${LeaBaseUrl}/api/roles?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}


export { GetRolesSorting };

