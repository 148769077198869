import axios from 'axios';
import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { GetHeadersContentAuth } from '../../Shared/AuthShared';



const sendCreateRequest = (postRule) => {

    console.log(" postRule : ", postRule);

    // Configuring the request
    const config = {
        headers: GetHeadersContentAuth()
    };

    console.log("config : " , config );


    // URL of the API endpoint
    const url = `${LeaBaseUrl}/api/cmds`;

    // Sending the POST request
    return axios.post(url, postRule, config)
        .then(response => {
            console.log('Response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            return Promise.reject(error);
        });
}

const sendEditRequest = (patchRule) => {

    console.log(" patchGroup : ", patchRule);

    const id = patchRule.id;

    // Configuring the request
    const config = {
        headers: GetHeadersContentAuth()
    };

    // URL of the API endpoint
    const url = `${LeaBaseUrl}/api/cmds?id=eq.${id}`;
    // const url = ``;

    // Sending the Patch request
    return axios.patch(url, patchRule, config)
        .then(response => {
            console.log('Response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            return Promise.reject(error);
        });
}

const sendDeleteRequest = (deleteRule) => {

    console.log(" deleteGroup : ", deleteRule);

    const id = deleteRule.id;


    // Configuring the request
    const config = {
        headers: GetHeadersContentAuth()
    };

    // URL of the API endpoint
    const url = `${LeaBaseUrl}/api/cmds?id=eq.${id}`;
    // const url = ``;

    // Sending the Patch request
    return axios.delete(url, config)
        .then(response => {
            console.log('Response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            return Promise.reject(error);
        });
}

export { sendCreateRequest };
export { sendEditRequest };
export { sendDeleteRequest };
