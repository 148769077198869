

import {GetHeadersContentAuth } from './AuthShared';

const shortenString = (field, numOfChars = 24) => {

    if (field.length > numOfChars) {
        field = field.slice(0, numOfChars) + ' ...';
    }

    return field;
}

const fomattedTimeDisplay = (timeTs) => {
    const dateTime = new Date(timeTs).toLocaleString("en-GB",
        {
            timeZone: 'Asia/Jerusalem',
        })


    const [date, time] = dateTime.split(', ');
    const timeDate = `${time}, ${date}`;

    // return dateTime;
    return timeDate;
}


// const exceptions = ['of', 'the', 'and'];

// function convertToTitleCase(str) {
//   if (!str) {
//       return ""
//   }

//   return str.toLowerCase().split(' ').map((word, i) => {
//             return exceptions.includes(word) && i !== 0 ? word : word.charAt(0).toUpperCase().concat(word.substr(1));
//         }).join(' ');
// } 


function convertToTitleCase(str) {
    if (!str) {
        return ""
    }

    return str.toLowerCase().split(' ').map((word, i) => {
        return i !== 0 ? word : word.charAt(0).toUpperCase().concat(word.substr(1));
    }).join(' ');
}



function isPositiveWholeNumber(str) {
    // Try to convert the string to a number
    const number = Number(str);

    // Check if the conversion resulted in a valid number and if it is an integer greater than zero
    return Number.isInteger(number) && number >= 0;
}

class SortState {
    constructor(defaultField = 'date', defaultAsc = true) {
        this.field = defaultField;
        this.asc = defaultAsc;
    }

    setSort(field) {
        if (this.field === field) {
            // If the same field is selected, toggle the order
            this.asc = !this.asc;

        } else {
            // If a different field is selected, start with ascending order
            this.field = field;
            this.asc = true;
        }
    }

    getSort() {
        return {
            field: this.field,
            asc: this.asc
        };
    }
}


class DataByIdsState {

    constructor(isActive = false) {
        this.isActive = isActive;

        // this.field = defaultField;
        // this.asc = defaultAsc;
    }

    setSort(field) {
        if (this.field === field) {
            // If the same field is selected, toggle the order
            this.asc = !this.asc;

        } else {
            // If a different field is selected, start with ascending order
            this.field = field;
            this.asc = true;
        }
    }

    getSort() {
        return {
            field: this.field,
            asc: this.asc
        };
    }

    resetToInit() {
        this.isActive = false;
    }
}

// // Example usage:
// const sortState = new SortState();

// // Default sorting
// console.log(sortState.getSort()); // { field: 'date', asc: true }

// // Pick a different field to sort by
// sortState.setSort('name');
// console.log(sortState.getSort()); // { field: 'name', asc: true }

// // Pick the same field again to reverse the order
// sortState.setSort('name');
// console.log(sortState.getSort()); // { field: 'name', asc: false }



const extractCountPartFromContentRangeHeader = (headerResponse) => {
    const headerContentRange = "content-range";
    const count = +(headerResponse[headerContentRange].split("/")[1]);
    return count;
};


const AuthAndCountHeaders = () => {

    const headers = {
        ...GetHeadersContentAuth(),
        Prefer: "count=exact",
    };

    return headers;
};

const GetDataAndCountResponse = (resp) => {
    const countTotal = extractCountPartFromContentRangeHeader(resp.headers);
    const resultDataAndCount = {
        data: resp.data,
        count: countTotal,
    }

    return resultDataAndCount;
}




function logIfTsPassedNDays(ts, verified, n) {
    // Convert the `ts` property to a JavaScript Date object
    const tsDate = new Date(ts);
  
    // Get the current date
    const currentDate = new Date();
  
    // Calculate the difference in time between the current date and the `ts` date
    const diffInTime = currentDate - tsDate;
  
    // Convert the time difference from milliseconds to days
    const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
  
    let verifiedResult = verified;
  
    // Check if the difference in days is greater than or equal to `n`
    if (diffInDays >= n) {
    //   console.log(verifiedResult);
    //   console.log(" -------- Passed ------------------>>>>> ");
      verifiedResult = null;
    }
    else {
    //   console.log(" -------- NOT ++ ][] ");
    }
  
    return verifiedResult
  }
  



export { logIfTsPassedNDays };

export { AuthAndCountHeaders };
export { GetDataAndCountResponse };
export { extractCountPartFromContentRangeHeader };
export { SortState };
export { fomattedTimeDisplay };
export { shortenString };
export { convertToTitleCase };
export { isPositiveWholeNumber };

