import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Box, Container, CssBaseline } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import Sidebar from "./Components/Sidebar/Sidebar";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";


import Execution from "./Components/Execution/Execution";
import Compliancy from "./Components/Compliancy/Compliancy";


import TopHeader from "./TopHeader";
import Rules from "./Components/Rules/Rules";
import Users from "./Components/Users/Users";
import Settings from "./Components/Settings/Settings";
import Roles from "./Components/Roles/Roles";
import Legal from "./Components/Legal/Legal";

import PageNotFound from "./Components/PageNotFound/PageNotFound";

import "./Navigation.css";


import Groups from "./Components/Groups/Groups";

// import GameOfLife from "./Components/GameOfLife/GameOfLife";
import GameOfLife from "./Components/GameOfLife/GameOfLifeComponent";
import DinoGameplay from "./Components/DinoGame/DinoGameplayComponent";




export default function Navigations() {



  const [isOpen, setIsOpen] = useState(true);

  const drawerToggleHandler = () => {
    setIsOpen((prev) => !prev);
  };

  const storedLocation = JSON.parse(window.localStorage.getItem("location"));
  const initialLocation = storedLocation;
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = window.localStorage.getItem("isLoggedIn");
    return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
  });

  useEffect(() => {

    // TODO : make or ask about the token if i use it right all
    //        ok for our use cases.

    const token = window.localStorage.getItem("token");
    if (token) {

      // check if i can delete the timing to localstorage 
      const loginTime = JSON.parse(window.localStorage.getItem("loginTime"));
      const expirationTime = 9 * 60 * 60 * 1000; // 9 hours
      if (loginTime && Date.now() - loginTime < expirationTime) {
        setIsLoggedIn(true);
      }

    }
    else {
      setIsLoggedIn(false);
      window.localStorage.removeItem("token");

      // check if i can delete the timing to localstorage 
      window.localStorage.removeItem("loginTime");
      window.localStorage.removeItem("isLoggedIn");

    }

  }, []);

  const storeLocation = () => {
    const location = window.location.pathname;
    window.localStorage.setItem("location", JSON.stringify(location));
  };

  const handleLogin = () => {
    setIsLoggedIn(true);

    // check if i can delete the timing to localstorage 
    window.localStorage.setItem("loginTime", JSON.stringify(Date.now()));
    window.localStorage.setItem("isLoggedIn", JSON.stringify(true));
  };

  const handleLogOut = () => {
    setIsLoggedIn(false);
    window.localStorage.removeItem("token");

    // check if i can delete the timing to localstorage 
    window.localStorage.removeItem("loginTime");
    window.localStorage.removeItem("isLoggedIn");
  };

  const renderRoutesPaths = () => {
    if (!isLoggedIn) {
      return (
        <>
          <Route path="/gameoflife" element={<GameOfLife />} />
          <Route path="/*" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={
              <Login onLogin={handleLogin} setIsLoggedIn={setIsLoggedIn} />
            }
          />
        </>
      );
    } else {
      return (
        <>
          <Route path="/" element={<Dashboard isLoggedIn={isLoggedIn} />} />
          <Route
            path="/dashboard"
            element={<Dashboard isLoggedIn={isLoggedIn} />}
          />
          <Route path="/compliancy" element={<Compliancy logout={handleLogOut} />} />
          <Route path="/execution" element={<Execution logout={handleLogOut} />} />
          <Route path="/rules" element={<Rules logout={handleLogOut} />} />


          <Route path="/groups" element={<Groups />} />


          <Route path="/users" element={<Users />} />
          <Route path="/roles" element={<Roles logout={handleLogOut} />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/legal" element={<Legal />} />

          {/* <Route path="/gameoflife" element={<GameOfLife />} /> */}
          {/* <Route path="/gameoflife" element={<GameOfLife />} /> */}
          <Route path="/gameoflife" element={<DinoGameplay />} />

          <Route path="/*" element={<PageNotFound />} />
        </>
      );
    }
  };


  const renderSideBar = () => {
    if (isLoggedIn) {
      return (
        <>
          <TopHeader
            toggle={drawerToggleHandler}
            onLogOut={handleLogOut}
            isOpen={isOpen}
            isLoggedIn={isLoggedIn}
          />

          <Sidebar
            drawerToggle={drawerToggleHandler}
            isOpen={isOpen}
            handleLogOut={handleLogOut}
            isLoggedIn={isLoggedIn}
            onLogOut={handleLogOut}
          />
        </>
      );
    }
    return <>
      <TopHeader
        toggle={drawerToggleHandler}
        onLogOut={handleLogOut}
        isOpen={isOpen}
        isLoggedIn={isLoggedIn}
      />
    </>;
  };

  return (
    <Grid container justify="space-around" disableEqualOverflow spaing={0}>
      <BrowserRouter>
        <Grid xs={2}>
          {renderSideBar()}
        </Grid>
        <Grid xs={10} justifyContent="left" alignItems="left" style={{ marginTop: "90px" }}>
          <Routes location={initialLocation} onUpdate={storeLocation}>
            {renderRoutesPaths()}
          </Routes>
        </Grid>
      </BrowserRouter>
    </Grid>
  );
}
