import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListIcon from '@mui/icons-material/List';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Card, CardContent } from '@mui/material';

import { fomattedTimeDisplay } from '../../Shared/UtillsJsFunctions';
import { logIfTsPassedNDays } from '../../Shared/UtillsJsFunctions';
// import { ColoringValidation } from '../../Shared/ThemesColor';


import TablePagination from '@mui/material/TablePagination';


import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';

import SearchPopoverServer from '../SearchPopover/SearchPopoverServer';



import { GetClientsSorting } from './CompliancyRequests';
import { GetSearchCompliancyElements } from './CompliancyRequests';

import { functionChangeElement } from '../../Shared/FunctionChange';


import { ColoringValidationSettings } from '../../Shared/ThemesColor';

import { GetSettingsUi } from '../../Shared/HttpRequests';



function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const nav = useNavigate();

  const id = row.id;

  const redirectToExecution = () => {

    const restRedirectUrl = `/execution?client_id=${id}`

    console.log("Redirect");
    nav(restRedirectUrl);

  };


  const inactive_status_color = props.inactive_status_color || "gold";



  // TODO :  continue! the coloring of the number and more.


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, padding: 0, margin: 0 }}   >
        <TableCell sx={{ width: 0, padding: 0 }}>
          <Box sx={ColoringValidationSettings(row.verified, inactive_status_color)} ></Box>
        </TableCell>
        <TableCell sx={{ padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ paddingLeft: 2 }}> {row.name} </TableCell>
        <TableCell sx={{ paddingLeft: 2 }} > {row.lastSeen} </TableCell>
        <TableCell sx={{ paddingLeft: 2 }} > {row.distribution} </TableCell>
        <TableCell sx={{ paddingLeft: 2 }} > {row.version} </TableCell>
        <TableCell align="center" sx={{ width: 0, padding: 0 }}>
          <Button onClick={redirectToExecution} >
            <ListIcon sx={{ marginRight: 3 }}></ListIcon>
          </Button>
        </TableCell>
      </TableRow>

      <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0, border: 0 }} colSpan={6} size='small'>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginLeft: 6, marginBottom: 2 }}>
            <Box sx={{ margin: 1 }}>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>LEA Agent Version</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Registered</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>IP Address</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Last Seen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {row.details && row.details.map((detailsRow) => (
                    <TableRow key={detailsRow.name} >
                      <TableCell component="th" scope="row">
                        {detailsRow.info_version}
                      </TableCell>
                      <TableCell>{detailsRow.reg_ts}</TableCell>
                      <TableCell >{detailsRow.ip_address}</TableCell>
                      <TableCell >
                        {detailsRow.res_max_ts}
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </Box>


            {/* version release system machine */}


            <Box sx={{ margin: 1 }}>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Version</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Release</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>System</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Machine</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {row.details && row.details.map((detailsRow) => (
                    <TableRow key={detailsRow.name} >
                      <TableCell component="th" scope="row">
                        {detailsRow.version}
                      </TableCell>
                      <TableCell>{detailsRow.release}</TableCell>
                      <TableCell >{detailsRow.system}</TableCell>
                      <TableCell >
                        {detailsRow.machine}
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </Box>





          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



const transformedDataArrAction = (clientsData, inactiveTime) => {  // dataClients 

  const transformedDataAry = clientsData.map(item => {

    const lastSeenFormat = fomattedTimeDisplay(item.ts);
    const ragTsFormat = fomattedTimeDisplay(item.reg_ts || item.ts);
    const resMaxTsFormat = fomattedTimeDisplay(item.ts);

    console.log(" item ", item);

    console.log(" transformedDataArrAction inactiveTime ", inactiveTime);

    // Check if `ts` is older than 2 days
    item.verified = logIfTsPassedNDays(item.results_max_ts, item.verified, inactiveTime);

    return ({
      id: item.id,
      name: item.name, // item.name,
      compliancy: item.active ? true : false,
      lastSeen: lastSeenFormat, // check to format if match to us
      distribution: item.info.dist.name,
      version: item.info.dist.version,
      verified: item.verified,

      details: [
        {
          info_version: item.info.version,
          reg_ts: ragTsFormat,
          ip_address: item.info.ip_address,
          res_max_ts: resMaxTsFormat,



          version: item.info.dist.version,
          release: item.info.dist.release,
          system: item.info.uname.system,
          machine: item.info.uname.machine,

        },
      ],
    }
    )
  }
  );

  return transformedDataAry;
}


export default function CollapsibleTable(props) {


  functionChangeElement.setNewAction(() => {

    const offset = page;
    const limit = rowsPerPage;

    console.log("%c Compliancy! ", "background: red ;");
    handleClickedHeader(-1, offset, limit);

  });

  const [totalCount, setTotalCount] = useState(20);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [inactiveStatusColor, setInactiveStatusColor] = React.useState("red");
  const [inactiveTime, setInactiveTime] = React.useState(14);

  const [settingsLoaded, setSettingsLoaded] = useState(false); // New state to track settings loading


  // const GetSettingsUiApiCall = async () => {

  //   try {

  //     const settings_ui_Obj = await GetSettingsUi();

  //     console.log(" settings_ui_Obj ", settings_ui_Obj);

  //     const settings_ui_Obj_data_list = settings_ui_Obj[0].settings;

  //     const  inactive_time = settings_ui_Obj_data_list.inactive_time;
  //     const  inactive_status_color = settings_ui_Obj_data_list.inactive_status_color;

  //     setInactiveTime(inactive_time);
  //     setInactiveStatusColor(inactive_status_color);

  //     console.log("inactive_time ", inactive_time);
  //     console.log("inactive_status_color ", inactive_status_color);

  //   }
  //   catch (error) {
  //     console.error("Error fetching data:", error);

  //   } finally {

  //   }
  // }

  // GetSettingsUiApiCall();

  // Fetch settings on component mount
  useEffect(() => {
    const initializeSettings = async () => {
      try {
        const settings_ui_Obj_data_list = await GetSettingsUi();
        const settings_ui_Obj = settings_ui_Obj_data_list[0].settings;

        console.log("settings_ui_Obj", settings_ui_Obj);

        setInactiveTime(settings_ui_Obj.client_inactive_time);
        setInactiveStatusColor(settings_ui_Obj.client_inactive_status_color);

        setSettingsLoaded(true); // Mark settings as loaded
      } catch (error) {
        console.error("Error fetching settings data:", error);
      }
    };
    initializeSettings();
  }, []); // Runs only once on component mount

  // Fetch data only after settings are loaded
  useEffect(() => {
    if (settingsLoaded) {
      fetchData(page, rowsPerPage);
    }
  }, [settingsLoaded, page, rowsPerPage]);

  const fetchData = (offset, limit) => {
    handleClickedHeader(-1, offset, limit);
  };



  const handleChangePage = (event, newPage) => {

    const offset = newPage;
    const limit = rowsPerPage;

    setPage(offset);

    handleClickedHeader(-1, offset, limit);
  };

  const handleChangeRowsPerPage = (event) => {

    const offset = 0;
    const limit = +event.target.value;

    setRowsPerPage(limit);
    setPage(offset);

    handleClickedHeader(-1, offset, limit);
  };

  const [clientHosts, setClientHosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  // useEffect(() => {

  //   const offset = page;
  //   const limit = rowsPerPage;

  //   handleClickedHeader(-1, offset, limit);

  // }, []);

  const colsHeaderNames = [
    { title: "Host Name", fieldName: "name" },
    { title: "Last Seen", fieldName: "ts" },
    { title: "Distribution", fieldName: "info->dist->>name" },
    { title: "Version", fieldName: "info->>version" },
  ];

  const [colsIndex, setColsIndex] = useState(-1);
  const [reverseSort, setReverseSort] = useState(false);

  const [lastSortKey, setLastSortKey] = useState(colsHeaderNames[0].fieldName);

  const checkReverse = (index) => {
    let reverse = reverseSort;

    if (index === colsIndex) {
      reverse = !reverse;
    } else {
      reverse = false;
    }

    setReverseSort(reverse);
    return reverse;
  }


  const handleClickedHeader = (index, offset, limit) => {

    const reverse = index > -1 ? checkReverse(index) : reverseSort;

    let key = lastSortKey; // "name";

    setColsIndex(index);

    // name lastSeen distribution version
    if (0 <= index && index < colsHeaderNames.length) {
      key = colsHeaderNames[index].fieldName;
      setLastSortKey(key);
    }

    setIsLoading(true);

    // console.log(" key ", key);

    const totalOffset = offset * limit;
    apiCall(key, reverse, totalOffset, limit);
  }

  const filteringByValueApiCall = (value, key, id) => {
    setIsLoading(true);

    GetSearchCompliancyElements(id).then(response => {
      const transformedDataAry = transformedDataArrAction(response, inactiveTime);
      setClientHosts(transformedDataAry);
    })
      .catch(error => {
        console.log("error", error);
      }).finally(() => {
        setIsLoading(false);
      });
  }

  const handleSyncData = () => {

    const offset = page;
    const limit = rowsPerPage;

    handleClickedHeader(-1, offset, limit);
  }

  const apiCall = async (key, reverse, totalOffset, limit) => {

    setIsLoading(true);

    try {

      const compliancyDataAndCount = await GetClientsSorting(key, reverse, totalOffset, limit);

      const compliancyData = compliancyDataAndCount.data;
      const compliancyCount = compliancyDataAndCount.count;

      const transformedDataAry = transformedDataArrAction(compliancyData, inactiveTime);

      setTotalCount(compliancyCount)
      setClientHosts(transformedDataAry);
    }
    catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status < 400) { return; }

      props.logout();
    } finally {
      setIsLoading(false);
    }
  }



  return (

    <Card>
      <CardContent>

        {isLoading ?

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GradientCircularProgress ></GradientCircularProgress>
          </Box> :
          <>
            <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }} size='small'  >
                <TableHead>
                  <TableRow  >
                    <TableCell sx={{ padding: 1 }} >
                    </TableCell>

                    <TableCell sx={{ padding: 0 }} />

                    {colsHeaderNames.map((colItem, index) => (

                      <TableCell
                        // align={colItem.align}
                        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={() => handleClickedHeader(index, page, rowsPerPage)}

                      >

                        {/* <Box sx={{ backgroundColor: 'red', display: 'flex' }}> */}
                        <Box sx={{ display: 'flex' }}>

                          <Box>
                            {colItem.title}
                          </Box>
                          <Box sx={{ justifyItems: 'flex-end' }} >

                            {
                              colsIndex >= 0 && colsIndex === index ?
                                (reverseSort ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                                : null
                            }

                          </Box>
                        </Box>
                      </TableCell>

                    ))}

                    <TableCell sx={{ padding: 0, margin: 0, paddingRight: 0 }} >

                      <Box sx={{ display: "flex", justifyContent: 'flex-start', alignContent: 'center' }}>

                        < SearchPopoverServer
                          filteringByValue={filteringByValueApiCall}
                          handleSyncData={handleSyncData}
                        />

                      </Box>

                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >

                  {clientHosts.map((row) => (
                    <Row key={row.id} row={row} inactive_status_color={inactiveStatusColor} />
                  ))}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </>}

      </CardContent>

      {/* <p>
        {colsIndex}
      </p>
 */}

    </Card>

  );
}