import React from 'react';
import DinoGame from './DinoGameComponent';

function DinoGameplay() {
  return (
    <div className="App">
      <h1>Welcome to the Dinosaur Game!</h1>
      <DinoGame />
    </div>
  );
}

export default DinoGameplay;
