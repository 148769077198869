import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';


import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';

import ClearIcon from '@mui/icons-material/Clear';



export default function SelectedDisplayRule(props) {

    const ruleField = props.value;

    const keyField = Object.keys(ruleField)[0];

    const wrapField = "re"
    const field = ruleField && !ruleField[wrapField] ? keyField : Object.keys(ruleField[wrapField])[0];

    const fieldLabel = (ruleField && !ruleField[wrapField] ? keyField : `${wrapField} : ${Object.keys(ruleField[wrapField])[0]}`);

    const valueInput = (ruleField && !ruleField[wrapField] ? ruleField[field] : ruleField[wrapField][field]); //ruleField[wrapField][field]);

    const emptyFunction = (val) => { }

    const onDelete = props.onDelete || emptyFunction;

    const handleOnDelete = () => {
        onDelete(keyField, ruleField[keyField]);
    }

    return (
        // <Box sx={{ minWidth: 260 }}>
        <Box sx={{ marginBottom: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                <FormControl fullWidth>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                        <TextField id="outlined-basic" label="field" variant="outlined" disabled={true}
                            sx={{ width: 200, margin: 1 }}
                            value={fieldLabel}
                        />

                        <TextField id="outlined-basic" label="rule" variant="outlined" disabled={true}
                            sx={{ width: 300, margin: 1 }}
                            value={valueInput}
                        />

                        <Button onClick={handleOnDelete} >
                            <ClearIcon ></ClearIcon>
                        </Button>

                    </Box>
                </FormControl>
            </Box >
        </Box >
    );
}