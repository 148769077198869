import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from "react";

import MultiSelectComponent from '../MultiSelectComponent/MultiSelectComponent';

import Checkbox from '@mui/material/Checkbox';



import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { sendDeleteRequest, sendEditRequest } from './GroupsService';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import SaveIcon from '@mui/icons-material/Save';



import {
  GetAllClients, GetAllCmds
} from "../../Shared/HttpRequests";




export default function EditGroupsComponent(props) {

  const initGroupName = props.groupName || "";
  const initDescription = props.description || "";
  const initIsActive = props.is_active || false;

  console.log("initGroupName : ", initGroupName);
  console.log("initDescription : ", initDescription);
  console.log("initIsActive : ", initIsActive);

  const [groupName, setGroupName] = useState(initGroupName);
  const [description, setDescription] = useState(initDescription);

  const [isActive, setIsActive] = React.useState(initIsActive);


  const initSelectedCmds = props.initCmds || [];
  const initSelectedClients = props.initClients || [];

  let initClientsIds = initSelectedClients.map(el => el.value);
  let initCmdsIds = initSelectedCmds.map(el => el.value);

  const [dataClients, setDataClients] = useState(initClientsIds);
  const [cmds, setCmds] = useState(initCmdsIds);

  const groupId = props.groupId || "not have an id";


  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const updateDataClients = (event) => {
    console.log("Update from CreateGroupsComponent , event : ", event);
    const arr = event.map(el => el.value);
    // console.log("updateDataClients : arr : ", arr);
    setDataClients(arr);
    console.log("clients : ", cmds);
  };

  const updateDataCmds = (event) => {
    console.log("Update from CreateGroupsComponent , event : ", event);
    const arr = event.map(el => el.value);
    // console.log("updateDataCmds : arr : ", arr);
    setCmds(arr);
    console.log("cmds : ", cmds);
  };

  const onEdit = () => {

    const dataObj = {
      name: groupName,
      description: description,
      active: isActive,
      clients_id: dataClients,
      cmd_id: cmds,
      id: groupId
    };


    sendEditRequest(dataObj).then(
      response => {
        console.log("[Edit] : dataObj : ", dataObj);
        console.log('Groups : Response:', response);
      })
      .catch(error => {
        console.log('Groups : Error:', error);
      }).finally(() => {
        window.location.reload();
      });
  }

  const onDelete = () => {

    const dataObj = {
      name: groupName,
      description,
      active: isActive,
      clients_id: dataClients,
      cmd_id: cmds,
      id: groupId
    };

    sendDeleteRequest(dataObj).then(
      response => {
        console.log("[Delete] : dataObj : ", dataObj);
        console.log('Groups : Response:', response);
      })
      .catch(error => {
        console.log('Groups : Error:', error);
      }).finally(() => {
        window.location.reload();
      });
  }



  const [clientsOptions, setClientsOptions] = useState([]);
  const [cmdsOptions, setCmdsOptions] = useState([]);

  // const [loadingClients, setLoadingClients] = useState(true);
  // const [loadingCommands, setLoadingCommands] = useState(true);
  const [error, setError] = useState(null);

  const transformToOptionObjs = (datalist) => {
    if (!datalist || datalist.length <= 0) {
      return [];
    }

    return datalist.map(el => { return { label: el.name, value: el.id } });
  }


  React.useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsData = await GetAllClients();
        const clientsDataOptions = transformToOptionObjs(clientsData);

        setClientsOptions(clientsDataOptions);
      } catch (err) {
        setError(err.message);
      } finally {
        // setLoadingClients(false);
      }
    };

    const fetchCommands = async () => {
      try {
        const commandsData = await GetAllCmds();
        const commandsDataOptions = transformToOptionObjs(commandsData);

        setCmdsOptions(commandsDataOptions);
      } catch (err) {
        setError(err.message);
      } finally {
        // setLoadingCommands(false);
      }
    };

    fetchClients();
    fetchCommands();
  }, []);



  return (
    <Card sx={{ width: 800 }}>
      <Box sx={{ width: '100%', padding: 4 }}  >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: "center" }} >
              {/* <h2> <span style={{ background: 'rgb(100 149 237 / 20%)', borderRadius: 12 }}>E</span>dit Group</h2> */}
              <h2> {initGroupName} </h2>
              {/* <h2> {groupName} </h2> */}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: "left", marginLeft: 10 }}  >

              <h4 > Active : </h4>
              {/* <ToggleButtonGroup style={{ padding: 12 }}
                value={isActive}
                exclusive
                onChange={handleActive}
                aria-label="isActive"
              >

                <ToggleButton value={true} aria-label="active">
                  <CheckIcon />
                </ToggleButton>
                <ToggleButton value={false} aria-label="notactive">
                  <CloseIcon />
                </ToggleButton>
              </ToggleButtonGroup> */}

              <Checkbox
                defaultChecked={isActive}
                onChange={() => setIsActive(!isActive)}
              ></Checkbox>

            </Box>


          </Grid>

          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            {/* <Box sx={{ display: 'flex', justifyContent: "center" }} > */}
            <Box sx={{ display: 'flex' }}>
              {/* <h3> ID : {groupId} </h3>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: "right" }} > */}


              <Button onClick={onEdit}> <SaveIcon /> </Button>
              {/* <Button color="error" onClick={onDelete}> <DeleteIcon /> </Button> */}
              <Button onClick={onDelete}> <DeleteIcon /> </Button>

            </Box>
          </Grid>


          <Grid item xs={6}>
            <Box  >
              <Box sx={{ marginLeft: 4 }}>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
                <h4 > Name  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                <TextField
                  placeholder="Name"
                  sx={{ width: 300 }}
                  onChange={handleGroupNameChange}
                  value={groupName}
                />
              </Box>
            </Box>

          </Grid>
          <Grid item xs={6}>
            <Box >
              {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} > */}
              <Box sx={{ marginLeft: 4 }}>
                <h4 > Description  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                <TextField
                  placeholder="Description"
                  sx={{ width: 300 }}
                  onChange={handleDescriptionChange}
                  value={description}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <MultiSelectComponent
              updateData={updateDataClients}
              title={"Hosts"}
              options={clientsOptions}
              initSelected={initSelectedClients}

            ></MultiSelectComponent>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <MultiSelectComponent
              updateData={updateDataCmds}
              title={"Rules"}
              options={cmdsOptions}
              initSelected={initSelectedCmds}

            ></MultiSelectComponent>
          </Grid>


          {/* <Grid item xs={12}>

            <Box sx={{ display: 'flex', justifyContent: 'space-around' }} >
              <Button onClick={onEdit}> <SaveIcon /> </Button>

              <Button color="error" onClick={onDelete}> <DeleteIcon /> </Button>
            </Box>

          </Grid> */}

        </Grid>
      </Box>
    </Card >
  );
}