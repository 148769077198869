// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-container {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #f7f7f7;
  overflow: hidden;
  border: 2px solid #ccc;
  margin-top: 50px;
}

.dino {
  position: absolute;
  bottom: 0;
  width: 40px;
  height: 40px;
  background-color: #555;
  transition: bottom 0.4s;

  border-radius: 4px;
}

/* .dino {
    position: absolute;
    bottom: 0;
    width: 20px;
    height: 20px;
    background-color: #555; /* Change to your desired color */
/* border-radius: 2px; /* Adds a bit of rounding to soften the square */
/*  } */

.jump {
  bottom: 100px;
}

.cactus {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 40px;
  background-color: #a22;
  border-radius: 4px;
}

.score {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  color: #333;
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #a22;
  text-align: center;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #a22;
  color: white;
  border: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/DinoGame/DinoGame.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;;EAEvB,kBAAkB;AACpB;;AAEA;;;;;6DAK6D;AAC7D,uEAAuE;AACvE,OAAO;;AAEP;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,eAAe;EACf,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".game-container {\n  position: relative;\n  width: 100%;\n  height: 200px;\n  background-color: #f7f7f7;\n  overflow: hidden;\n  border: 2px solid #ccc;\n  margin-top: 50px;\n}\n\n.dino {\n  position: absolute;\n  bottom: 0;\n  width: 40px;\n  height: 40px;\n  background-color: #555;\n  transition: bottom 0.4s;\n\n  border-radius: 4px;\n}\n\n/* .dino {\n    position: absolute;\n    bottom: 0;\n    width: 20px;\n    height: 20px;\n    background-color: #555; /* Change to your desired color */\n/* border-radius: 2px; /* Adds a bit of rounding to soften the square */\n/*  } */\n\n.jump {\n  bottom: 100px;\n}\n\n.cactus {\n  position: absolute;\n  bottom: 0;\n  width: 20px;\n  height: 40px;\n  background-color: #a22;\n  border-radius: 4px;\n}\n\n.score {\n  position: absolute;\n  top: 10px;\n  left: 10px;\n  font-size: 20px;\n  color: #333;\n}\n\n.game-over {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-size: 24px;\n  color: #a22;\n  text-align: center;\n}\n\nbutton {\n  margin-top: 10px;\n  padding: 5px 10px;\n  background-color: #a22;\n  color: white;\n  border: none;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
