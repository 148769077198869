// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contain {
    display: flex;
    width: 100vw;
    background-color: #F6EFF4;
}
`, "",{"version":3,"sources":["webpack://./src/Navigation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".contain {\n    display: flex;\n    width: 100vw;\n    background-color: #F6EFF4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
