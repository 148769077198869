


const activeBarColor = '#96FFE8'; // '#000' 
const notActiveBarColor = '#FF96AD'; // '#CCC'

const lavenderBlush = '#FFF5FD';
const bakerMillerPink = '#FF96AD';
const mediumElectronicBlue = '#005A8D';
const prussianBlue = '#022E57';


const ColoringValidation = (compliancy) => {

    // console.log(" coloring ? : " , compliancy === 'true' ? '#FFF' : '#CCC')
    // console.log(" coloring ? : " , compliancy === true ? '#FFF' : '#CCC')

    return {

        width: '7px',
        // height: '40px',

        height: '40px',
        // height: '100%',

        backgroundColor: compliancy ? activeBarColor : notActiveBarColor,
    }
}


const ColoringValidationSettings = (verified, inactive_status_color = '#CCC') => {

    // console.log(" coloring ? : " , compliancy === 'true' ? '#FFF' : '#CCC')
    // console.log(" coloring ? : " , compliancy === true ? '#FFF' : '#CCC')

    let color = inactive_status_color;

    if (verified === true) {
        color = activeBarColor;
    } else if (verified === false) {
        color = notActiveBarColor;
    }
    else if (verified === null) {
        color = inactive_status_color;
    }

    return {

        width: '7px',
        // height: '40px',
        height: '40px',
        // height: '100%',
        backgroundColor: color,
    }
}




export { ColoringValidationSettings };

export { ColoringValidation };
export { activeBarColor };
export { notActiveBarColor };