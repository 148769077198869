import * as React from 'react';
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Card, CardContent } from '@mui/material';

import { fomattedTimeDisplay } from '../../Shared/UtillsJsFunctions';
// import { ColoringValidation } from '../../Shared/ThemesColor';
import { activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';

import TablePagination from '@mui/material/TablePagination';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';
import { useSearchParams } from 'react-router-dom';

import { GetCommandResultsByClientId } from "./ExecutionRequests"
import { GetCommandResultsSortingNew } from "./ExecutionRequests"

import { functionChangeElement } from '../../Shared/FunctionChange';

import SearchPopoverServer from '../SearchPopover/SearchPopoverServer';

import { GetCommandResultsByClientAndRuleIDs } from "./ExecutionRequests";
import { GetCommandResultsByCmdId } from "./ExecutionRequests";
import { GetCommandResultsByGroup } from "./ExecutionRequests";

import { SortState } from "../../Shared/UtillsJsFunctions";


import { logIfTsPassedNDays } from '../../Shared/UtillsJsFunctions';
import { GetSettingsUi } from '../../Shared/HttpRequests';


import { ColoringValidationSettings } from '../../Shared/ThemesColor';


import { shortenString } from '../../Shared/UtillsJsFunctions'


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState(row.details || []);

  const stdoutShortenNumber = props.stdoutShortenNumber || 32;
  const stderrShortenNumber = props.stderrShortenNumber || 32;

  const numberRowsExecution = props.numberRowsExecution || 200;




  const handleOnClickExpandCollapse = () => {
    const toOpen = !open;
    setOpen(toOpen);

    console.log(" Hey Expand Collapse : state : ", toOpen ? "to open -> api call" : "to close -> close");

    if (toOpen) {

      console.log(" API CALL , row", row);

      if (!row) {
        console.log("props . row : ", row);
        return (<> "Error props.row null"</>);
      }

      if (!row.details) {
        console.log("props.row.details : ", row.details);
      }

      // const details = row.details;
      // A.splice(0,A.length) // clearing array in javascript 
      // row.details = [];

      const fetchDetails = async () => {
        try {

          const arrDetails = await GetCommandResultsByClientAndRuleIDs(row.clientId, row.cmdId , numberRowsExecution);

          console.log(" arrDetails : ", arrDetails);

          const detailsResults = arrDetails["data"].map(createTransformObjectData);
          setDetails(detailsResults);
          console.log(" detailsResults : ", detailsResults);

          console.log(" details ", details);
          console.log(" row.details ", row.details);

        } catch (error) {
          console.log("error : ", error);

        }
        finally {

        }


      }

      fetchDetails();




    }


  };


  const inactive_status_color = props.inactive_status_color || "gold";


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, padding: 0, margin: 0 }} >

        <TableCell align="" sx={{ width: 0, padding: 0 }}>
          <Box sx={ColoringValidationSettings(row.verified, inactive_status_color)} ></Box>
        </TableCell>

        <TableCell sx={{ width: 0, padding: 0, margin: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleOnClickExpandCollapse}
          >
            {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row" sx={{ paddingLeft: 2, }}> {row.name} </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, }}>{row.rule}</TableCell>
        <TableCell sx={{ paddingLeft: 2, }}> {shortenString(row.stdout, stdoutShortenNumber)} </TableCell>
        <TableCell sx={{ paddingLeft: 2, }}> {shortenString(row.stderr, stderrShortenNumber)} </TableCell>
        <TableCell sx={{ paddingLeft: 2, }}> {row.returncode} </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, }}> {row.time} </TableCell>

        <TableCell align="" sx={{ width: 0, padding: 0 }}></TableCell>

      </TableRow>
      <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0, border: 0 }} colSpan={8} size='small'>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginLeft: 6, marginBottom: 2 }}>
            <Box sx={{ margin: 2 }}>
              <Table aria-label="subTable">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }} >Host Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} >Rule</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} >Stdout</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} >Stderr</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} >Return Code</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} >Type</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} >Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((detailsRow) => (
                    <TableRow key={detailsRow.clients}>
                      <TableCell sx={{ padding: 2, margin: 0 }}> {row.name} </TableCell>
                      <TableCell> {row.rule} </TableCell>
                      <TableCell> {detailsRow.stdout} </TableCell>
                      <TableCell> {detailsRow.stderr} </TableCell>
                      <TableCell> {detailsRow.returncode} </TableCell>
                      <TableCell> {detailsRow.type} </TableCell>
                      <TableCell> {detailsRow.time}</TableCell>
                    </TableRow>
                  ))}


                </TableBody>
              </Table>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', marginRight: 10, fontSize: 'x-small' }}>
                <p> total rows : {details.length} </p>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const transformedDataElementAction = (item, inactiveTime) => {

  const transformObjectDataResults = createTransformObjectData(item, inactiveTime);
  const transformObjectData = transformObjectDataResults;

  transformObjectData.details = [transformObjectDataResults];
  return transformObjectData;
  // return {};
}


const createTransformObjectData = (item, inactiveTime) => {

  const id = item.id;

  const clientName = item.client_name || "";
  const commandName = item.cmd_name || "";

  // const stdout =  shortenString( JSON.stringify(item.result.stdout) || "" );
  // const stdout =  shortenString( JSON.stringify(item.result.stdout, 32) || "" );
  const stdout = JSON.stringify(item.result.stdout) || "";
  // const stdout =(item.result.stdout) || "";

  // const stdout = "";
  const stderr = item.result.stderr || "";
  const returncode = item.result.returncode;

  const timeFormattedTs = fomattedTimeDisplay(item.ts);

  const clientId = item.clients_id;
  const cmdId = item.cmd_id;

  const verified = logIfTsPassedNDays(item.ts, item.verified, inactiveTime);

  const cmdType = item.cmd_type || "--";

  const transformObjectData = {
    verified: verified,
    id: id,

    name: clientName,
    rule: commandName,
    stdout: stdout,
    stderr: stderr,
    returncode: returncode,
    type: cmdType,
    time: timeFormattedTs,

    clientId: clientId,
    cmdId: cmdId,
  };

  return transformObjectData;
}


const transformedDataArrAction = (commandResultsData, inactiveTime) => {  // commandResultsData 

  if (!commandResultsData) { return []; }

  const transformedDataAry = commandResultsData.map(item => {
    return transformedDataElementAction(item, inactiveTime);
  });

  return transformedDataAry;
}


const colsHeaderNames = [
  { title: "Host Name", fieldName: "client_name" },
  { title: "Rule", fieldName: "cmd_name" },
  { title: "Stdout", fieldName: "result->>stdout" },
  { title: "Stderr", fieldName: "result->>stderr" },
  { title: "Return Code", fieldName: "result->>returncode" },
  { title: "Time", fieldName: "ts" },
];


const defaultAsc = true;
const indexTsOfColsHeaderNames = 5;
const initSortField = colsHeaderNames[indexTsOfColsHeaderNames].fieldName;
const sortState = new SortState(initSortField, !defaultAsc);


const objData = { haveValidDataClient: false, haveValidDataCmd: false, clientId: -1, cmdId: -1 };
const objDataGroups = { haveValidDataGroups: false, clientsIds: [], cmdsIds: [] };


const clearDataByIds = () => {

  objData.haveValidDataClient = false;
  objData.haveValidDataCmd = false;
  objDataGroups.haveValidDataGroups = false;
}


export default function CollapsibleTable(props) {

  functionChangeElement.setNewAction(() => {
    console.log("%c Execution! ", "background: blue ;");
    handleSyncData();
  });

  const [totalCount, setTotalCount] = useState(20);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);




  const [inactiveStatusColor, setInactiveStatusColor] = React.useState("red");
  const [inactiveTime, setInactiveTime] = React.useState(14);
  const [settingsLoaded, setSettingsLoaded] = useState(false); // New state to track settings loading


  const [stdoutShortenNumber, setStdoutShortenNumber] = React.useState(32);
  const [stderrShortenNumber, setStderrShortenNumber] = React.useState(32);

  const [numberRowsExecution, setNumberRowsExecution] = React.useState(200);

  // const [stderrShortenNumber, setStderrShortenNumber] = React.useState(200);
  


  // Fetch settings on component mount
  useEffect(() => {
    const initializeSettings = async () => {
      try {
        const settings_ui_Obj_data_list = await GetSettingsUi();
        const settings_ui_Obj = settings_ui_Obj_data_list[0].settings;

        // console.log("settings_ui_Obj", settings_ui_Obj);

        setInactiveTime(settings_ui_Obj.execution_inactive_time);
        setInactiveStatusColor(settings_ui_Obj.execution_inactive_status_color);

        setStdoutShortenNumber(settings_ui_Obj.stdout_shorten_number);
        setStderrShortenNumber(settings_ui_Obj.stderr_shorten_number);

        setNumberRowsExecution(settings_ui_Obj.number_rows_execution);

        // stdoutShortenNumber, stderrShortenNumber



        // setSettingsLoaded(true); // Mark settings as loaded

        // console.log("after setSettingsLoaded", settingsLoaded);

      } catch (error) {
        console.error("Error fetching settings data:", error);
      }
    };
    initializeSettings();
  }, []); // Runs only once on component mount

  // Fetch data only after settings are loaded
  useEffect(() => {
    // if (settingsLoaded) {
    //   fetchData();
    // }

    fetchData();

  }, [settingsLoaded, page, rowsPerPage]);



  const handleChangePage = (event, newPage) => {
    const offset = newPage;
    const limit = rowsPerPage;

    setPage(newPage);
    pickApiCall(offset, limit);
  };


  const handleChangeRowsPerPage = (event) => {
    const offset = 0;
    const limit = +event.target.value;

    setPage(offset);
    setRowsPerPage(limit);

    pickApiCall(offset, limit);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = {};
  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value;
  }

  const [dataClients, setDataClients] = useState([]);

  // const [isLoading, setIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const apiCallById = async (offset, limit) => {

    const totalOffset = offset * limit;
    const { field, asc } = sortState.getSort();

    // console.log(" field , asc : ", field, asc ? "asc" : "desc");
    setIsLoading(true);

    try {

      let commandResultsDataAndCount = { data: [], count: 0 };

      // console.log(" objData ", objData);

      if (objDataGroups.haveValidDataGroups) {
        commandResultsDataAndCount = await GetCommandResultsByGroup(objDataGroups.clientsIds, objDataGroups.cmdsIds, field, asc, totalOffset, limit);
      }
      else if (objData.haveValidDataClient) {
        commandResultsDataAndCount = await GetCommandResultsByClientId(objData.clientId, field, asc, totalOffset, limit);
      }
      else if (objData.haveValidDataCmd) {
        commandResultsDataAndCount = await GetCommandResultsByCmdId(objData.cmdId, field, asc, totalOffset, limit);
      }

      const commandResultsData = commandResultsDataAndCount.data;
      const commandResultsCount = commandResultsDataAndCount.count;
      const transformedDataAry = transformedDataArrAction(commandResultsData, inactiveTime);

      // console.log(" commandResultsData ", commandResultsData);
      // console.log(" elId elId : ", elId);
      setTotalCount(commandResultsCount);
      setDataClients(transformedDataAry);
    }
    catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status < 400) { return; }

      props.logout();
    } finally {
      setIsLoading(false);
    }
  };


  const apiCallAndDataTransform = async (offset, limit) => {

    setIsLoading(true);

    const totalOffset = offset * limit;

    const { field, asc } = sortState.getSort();

    // console.log(" field , asc : ", field, asc ? "asc" : "desc");

    try {

      const commandResultsDataAndCount = await GetCommandResultsSortingNew(field, asc, totalOffset, limit);
      const commandResultsData = commandResultsDataAndCount.data;
      const commandResultsCount = commandResultsDataAndCount.count;
      const transformedDataAry = transformedDataArrAction(commandResultsData, inactiveTime);

      setDataClients(transformedDataAry);
      setTotalCount(commandResultsCount);

    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status < 400) { return; }

      props.logout();

    } finally {
      setIsLoading(false);
    }
  };

  async function fetchData() {

    clearDataByIds();

    if (queryParams && queryParams.clientsIds && queryParams.cmdsIds) {
      const clientsIds = queryParams.clientsIds;
      const cmdsIds = queryParams.cmdsIds;

      objDataGroups.clientsIds = clientsIds;
      objDataGroups.cmdsIds = cmdsIds;
      objDataGroups.haveValidDataGroups = true;
    }


    if (queryParams && queryParams.client_id) {
      const clientId = +queryParams.client_id;

      objData.clientId = clientId;
      objData.haveValidDataClient = true;
    }
    else if (queryParams && queryParams.cmd_id) {
      const cmdId = +queryParams.cmd_id;

      objData.cmdId = cmdId;
      objData.haveValidDataCmd = true;
    }

    // console.log(" useEffect ", "Execution", "queryParams : ", queryParams);

    const offset = page;
    const limit = rowsPerPage;

    pickApiCall(offset, limit);

  }




  const handleClickedHeader = (index, offset, limit) => {

    setIsLoading(true);

    const keyField = colsHeaderNames[index].fieldName; // "active";
    sortState.setSort(keyField);

    pickApiCall(offset, limit);

  }

  const pickApiCall = async (offset, limit) => {

    if (objData.haveValidDataClient || objData.haveValidDataCmd || objDataGroups.haveValidDataGroups) {
      apiCallById(offset, limit);
    }
    else {
      apiCallAndDataTransform(offset, limit);
    }
  }


  const filteringByValue = (value, key, id) => {

    // console.log("picked & : value : ", value);
    // console.log("picked & : id : ", id);

    if (id < 0) { return; }

    const elId = id;
    objData.clientId = elId;
    objData.haveValidDataClient = true;

    const keyField = colsHeaderNames[indexTsOfColsHeaderNames].fieldName; // "active";
    sortState.setSort(keyField);

    const offset = page;
    const limit = rowsPerPage;

    apiCallById(offset, limit);
  }

  const deleteQueryParams = () => {
    searchParams.delete('cmd_id');
    searchParams.delete('client_id');

    searchParams.delete('clientsIds');
    searchParams.delete('cmdsIds');

    clearDataByIds();

    // 👇️ Update state after
    setSearchParams(searchParams);

  };


  const handleSyncData = async () => {
    const offset = page;
    const limit = rowsPerPage;

    deleteQueryParams();
    await apiCallAndDataTransform(offset, limit);
  }


  return (
    <Card>
      <CardContent>

        {isLoading ?

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GradientCircularProgress ></GradientCircularProgress>
          </Box> :
          <>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }} size='small'>
                  <TableHead>
                    <TableRow  >
                      <TableCell sx={{ padding: 1, margin: 0 }} />
                      <TableCell sx={{ padding: 0, margin: 0 }} />
                      {colsHeaderNames.map((colItem, index) => (
                        <TableCell
                          // align={colItem.align}
                          sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                          onClick={() => handleClickedHeader(index, page, rowsPerPage)}

                        >

                          <Box sx={{ display: 'flex' }}>
                            {/* <Box > */}

                            <Box>
                              {colItem.title}
                            </Box>
                            {/* <Box sx={{ justifyItems: 'flex-end' }} > */}
                            <Box >

                              {
                                colItem.fieldName === sortState.getSort().field ?
                                  (sortState.getSort().asc ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                                  : null
                              }

                            </Box>
                          </Box>
                        </TableCell>

                      ))}


                      <TableCell sx={{ padding: 0, margin: 0, paddingRight: 3 }} >

                        < SearchPopoverServer
                          filteringByValue={filteringByValue}
                          handleSyncData={handleSyncData}
                        />

                      </TableCell>

                    </TableRow>

                  </TableHead>

                  <TableBody >
                    {dataClients.map((row) => (
                      <Row key={row.id} row={row} 
                      inactive_status_color={inactiveStatusColor} 
                      stdoutShortenNumber={stdoutShortenNumber} 
                      stderrShortenNumber={stderrShortenNumber}
                      numberRowsExecution={numberRowsExecution}
                      />

                      // <Row key={row.id} row={row} inactive_status_color={'pink'} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>


              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}//{[1, 2, 10]}//{[10, 25, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

            </Paper>
          </>}

      </CardContent>
    </Card >
  );
}
