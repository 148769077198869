import React, { useState, useEffect } from 'react';
import './DinoGame.css';

const DinoGame = () => {
  const [dinoJumping, setDinoJumping] = useState(false);
  const [cactusPosition, setCactusPosition] = useState(100);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        handleJump();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dinoJumping]);

  useEffect(() => {
    if (!gameOver) {
      const cactusInterval = setInterval(() => {
        setCactusPosition(prev => (prev > 0 ? prev - 1 : 100));
      }, 20);

      const scoreInterval = setInterval(() => {
        setScore(prev => prev + 1);
      }, 100);

      return () => {
        clearInterval(cactusInterval);
        clearInterval(scoreInterval);
      };
    }
  }, [gameOver]);

  useEffect(() => {
    if (cactusPosition < 10 && !dinoJumping) {
      setGameOver(true);
    }
  }, [cactusPosition, dinoJumping]);

  const handleJump = () => {
    if (!dinoJumping) {
      setDinoJumping(true);
      setTimeout(() => {
        setDinoJumping(false);
      }, 500);
    }
  };

  const restartGame = () => {
    setGameOver(false);
    setCactusPosition(100);
    setScore(0);
  };

  return (
    <div className="game-container">
      <div className={`dino ${dinoJumping ? 'jump' : ''}`}></div>
      <div className="cactus" style={{ left: `${cactusPosition}%` }}></div>
      <div className="score">Score: {score}</div>
      {gameOver && <div className="game-over">Game Over! <button onClick={restartGame}>Restart</button></div>}
    </div>
  );
};

export default DinoGame;
