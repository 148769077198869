// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html{
  background: var(--White, #FFF);
  margin: 0;
  padding: 0;
}
.contain {
  display: flex;
  width: 100%;
}


button {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;AACZ;AACA;EACE,aAAa;EACb,WAAW;AACb;;;AAGA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["body, html{\n  background: var(--White, #FFF);\n  margin: 0;\n  padding: 0;\n}\n.contain {\n  display: flex;\n  width: 100%;\n}\n\n\nbutton {\n  cursor: pointer;\n}\n\na:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
