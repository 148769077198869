import Navigations from "./Navigations";
import './index.css';

function App() {

  return (
    <div className="app">
      <Navigations/>
    </div>
  );
}

export default App;
