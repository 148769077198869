
import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { AuthAndCountHeaders, GetDataAndCountResponse } from '../../Shared/UtillsJsFunctions';
import axios from 'axios';



// https://lea.gsr-it.com/api/clients_verified?order=id.asc&offset=0&limit=10



// ### GET Request
// GET https://lea.gsr-it.com/api/cmd_results?clients_id=eq.2


const GetCommandResultsByClientId = async (client_id, sortBy = "ts", orderAsc = false) => {

    const sortOrder = orderAsc ? "asc" : "desc";

    // console.log(" GetCommandResultsByClientId : searchParam :", client_id);

    // https://lea.gsr-it.com/api/clients_verified?select=info->dist->>name
    const url = `${LeaBaseUrl}/api/rpc/get_latest_cmd_results_by_client_id?p_client_id=${client_id}&order=${sortBy}.${sortOrder}`;
    // const url = `${LeaBaseUrl}/api/clients_verified?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;



    const config = {
        headers: AuthAndCountHeaders()
    };


    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}



const GetCommandResultsByCmdId = async (cmd_id, sortBy = "ts", orderAsc = false) => {

    const sortOrder = orderAsc ? "asc" : "desc";

    // console.log(" GetCommandResultsByClientId : searchParam :", client_id);

    // https://lea.gsr-it.com/api/clients_verified?select=info->dist->>name
    const url = `${LeaBaseUrl}/api/rpc/get_latest_cmd_results_by_cmd_id?p_cmd_id=${cmd_id}&order=${sortBy}.${sortOrder}`;
    // const url = `${LeaBaseUrl}/api/clients_verified?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}


const GetCommandResultsSortingNew = async (sortField, orderAsc = true, offset = 0, limit = 100) => {

    const sortOrder = orderAsc ? "asc" : "desc";
    const url = `${LeaBaseUrl}/api/latest_cmd_results_all?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}


// const GetCommandResultsByClientAndRuleIDs = async (clientId, cmdId, sortField = "ts", orderAsc = false, offset = 0, limit = 200) => {
const GetCommandResultsByClientAndRuleIDs = async (clientId, cmdId, limit = 200) => {

    const sortField = "ts", orderAsc = false, offset = 0;
    
    const sortOrder = orderAsc ? "asc" : "desc";
    const url = `${LeaBaseUrl}/api/cmd_results?clients_id=eq.${clientId}&cmd_id=eq.${cmdId}&order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}


// https://127.0.0.1//api/latest_cmd_results_all?and=(clients_id.in.(4,6,1,5),cmd_id.in.(1,4))


const GetCommandResultsByGroup = async (clientIds, cmdIds, sortField = "name", orderAsc = false, offset = 0, limit = 200) => {

    const sortOrder = orderAsc ? "asc" : "desc";
    const clientIdsJoinComma = clientIds;
    const cmdIdsJoinComma = cmdIds;

    const url = `${LeaBaseUrl}/api/latest_cmd_results_all?and=(clients_id.in.${clientIdsJoinComma},cmd_id.in.${cmdIdsJoinComma})&order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}


export { GetCommandResultsByGroup };
export { GetCommandResultsSortingNew };
export { GetCommandResultsByClientAndRuleIDs };
export { GetCommandResultsByClientId };
export { GetCommandResultsByCmdId };




