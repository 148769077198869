import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


function GradientCircularProgress() {
    const sizeBox = 100;

    return (
        <React.Fragment>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">

                        {/* <stop offset="0%" stopColor="#e01cd5" /> */}
                        {/* <stop offset="100%" stopColor="#1CB5E0" /> */}

                        <stop offset="0%" stopColor="#96FFE8" />
                        <stop offset="100%" stopColor="#FF96AD" />

                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress size={sizeBox} sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        </React.Fragment>
    );
}


export { GradientCircularProgress };
