
import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { GetHeadersContentAuth } from '../../Shared/AuthShared';
import axios from 'axios';

const UpdateSettings = (data) => {
    // Define the API endpoint
    const url = `${LeaBaseUrl}/api/settings_ui?name=eq.settings_front`;

    // Set up the request headers, including content type and authorization
    const config = {
        headers: {
            ...GetHeadersContentAuth(),
            'Prefer': 'return=representation' // Optional, returns the updated data
        }
    };

    // Ensure the incoming data is an object (not a string)
    const settingData = (typeof data === "string") ? JSON.parse(data) : data;

    console.log("Final Payload Data (object):", settingData);

    // Prepare the payload with the correctly formatted JSON object
    const payload = {
        "settings": settingData
    };

    console.log("Payload being sent:", payload);

    // Make the PATCH request using axios
    return axios
        .patch(url, payload, config)
        .then(resp => {
            const settings_ui_Obj = resp.data;
            return settings_ui_Obj;
        })
        .catch(err => {
            console.error("Error during update:", err);
            return Promise.reject(err);
        });
};



export { UpdateSettings };

