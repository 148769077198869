
import React, { useState, useEffect } from 'react';

import {
    Box,
} from "@mui/material";


import { GetLastestsResults } from './sideBarResuqest';
import Badge from '@mui/material/Badge';
import { useRef } from 'react';


import {
    Build as ExecutionIcon,
} from "@mui/icons-material";




const myMap = new Map();


function generateKey(client_id, cmd_id) {
    return `${client_id}_${cmd_id}`;
}




const ExecutionWithDotBadge = () => {
    const [invisible, setInvisible] = useState(false); // State to control badge visibility
    const [counter, setCounter] = useState(0); // Counter state to display in UI
    const counterRef = useRef(0); // To hold the current counter value without causing re-renders
    const intervalRef = useRef(null); // To store the interval reference

    const apiChecksCall = async () => {
        console.log(" -> ping apiChecksCall");

        try {
            const respData = await GetLastestsResults(); // Replace with your API call
            const data = respData;

            let flag = false; // Flag to determine if the badge should be visible

            data.forEach(item => {
                const key = generateKey(item.clients_id, item.cmds_id); // Assuming you have this logic
                const mapValue = myMap.get(key);

                if (mapValue !== undefined) {
                    if (mapValue === true && item.verified === false) {
                        flag = true;
                    }
                } else {
                    if (item.verified === false) {
                        flag = true;
                    }
                }

                // Optionally, update the map with the new value
                myMap.set(key, item.value);
            });

            console.log("Flag:", flag);

            // Update badge visibility based on the flag
            // setInvisible(!flag);
            setInvisible(flag);

        } catch (error) {
            console.log("API error:", error);
        }
    };

    useEffect(() => {
        // Function to increment the counter and call the API
        const incrementCounterAndCheckApi = () => {
            counterRef.current += 1; // Increment the counter ref
            console.log("Timer tick:", counterRef.current);
            setCounter(counterRef.current); // Trigger re-render with updated counter value

            // Call the API check function
            apiChecksCall();
        };

        // Start interval only if it doesn't exist
        if (!intervalRef.current) {
            incrementCounterAndCheckApi(); // Initial call to start
            intervalRef.current = setInterval(incrementCounterAndCheckApi, 25_000); // Run every 5 seconds
        }

        // Cleanup interval on component unmount
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current); // Clear the interval
                intervalRef.current = null; // Reset the interval reference
            }
        };
    }, []); // Empty dependency array ensures this only runs once on mount

    return (
        <Box sx={{ color: 'action.active' }}>
            <Badge color="primary" variant="dot" invisible={invisible}>
                <ExecutionIcon />
            </Badge>
            {/* <p>Counter: {counter}</p> */} {/* Display the counter */}
        </Box>
    );
};















// // import React, { useState, useEffect } from 'react';

// // import { Link, useNavigate, useLocation } from "react-router-dom";

// // import {
// //   Drawer,
// //   Box,
// //   List,
// //   ListItemText,
// //   ListItemButton,
// //   Typography,
// //   ListItemIcon,
// //   CssBaseline,
// //   Toolbar,
// //   Divider,
// // } from "@mui/material";

// // import {
// //   Dashboard as DashboardIcon,
// //   Assignment as CompliancyIcon,
// //   Build as ExecutionIcon,
// //   LibraryBooks as RulesIcon,
// //   Group as GroupsIcon,
// //   Extension as ModulesIcon,
// //   Notifications as NotificationsIcon,
// //   Person as UsersIcon,
// //   SupervisorAccount as RolesIcon,
// //   Settings as SettingsIcon,
// //   HelpOutline as FAQIcon,
// //   ExitToApp as LogoutIcon,
// // } from "@mui/icons-material";


// // import { activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';

// // import { GetLastestsResults } from './sideBarResuqest';

// // import Badge from '@mui/material/Badge';



// // import { useRef } from 'react';





// // const myMap = new Map();


// // function generateKey(client_id, cmd_id) {
// //   return `${client_id}_${cmd_id}`;
// // }




// // const ExecutionWithDotBadge = () => {

// //   const [invisible, setInvisible] = React.useState(false);

// //   // const myMap = new Map();

// //   const handleBadgeVisibility = () => {
// //     // setInvisible(!invisible);
// //     console.log(" here make a call  , invisible : ", invisible);
// //     console.log(" here make a call  , counter : ", counter);


// //     invisibleRef.current = !invisibleRef.current;
// //     setInvisible(invisibleRef.current);

// //   };

// //   const setInvisibleAndRef = (invisibleEl) => {
// //     // setInvisibleAndRef();

// //     invisibleRef.current = invisibleEl;
// //     setInvisible(invisibleRef.current);
// //     // setInvisible(invisibleEl);

// //     console.log("  ====> ");
// //   }

// //   // let counter = 0;

// //   const timerFunc = () => {
// //     // console.log(" -> timer call  ", counter);
// //     // console.log(" here make a call  , invisible : ", invisible);
// //     handleBadgeVisibility();
// //     // counter++;
// //   }

// //   const apiChecksCall = async () => {

// //     console.log(" -> ping apiChecksCall ");

// //     try {

// //       const respData = await GetLastestsResults();

// //       const data = respData;

// //       let flag = false;

// //       data.forEach(item => {

// //         const key = generateKey(item.clients_id, item.cmds_id);
// //         const mapValue = myMap.get(key);

// //         if (mapValue !== undefined) {
// //           // Key exists in the map
// //           if (mapValue === true && item.verified === false) {
// //             flag = true;
// //           }
// //         } else {
// //           // Key does not exist in the map
// //           if (item.verified === false) {
// //             flag = true;
// //           }
// //         }

// //         // Optionally, you can update the map with the new value
// //         myMap.set(key, item.value);

// //       });

// //       console.log("Flag:", flag);

// //       if (flag) {
// //         setInvisible(false);
// //       }


// //     } catch (error) {
// //       console.log(error);
// //     }
// //   }

// //   // // const interval = 5_000; // milliseconds

// //   // // // Call myFunction every 5 seconds (5000 milliseconds)
// //   // // setInterval(timerFunc, interval);

// //   const [counter, setCounter] = useState(0); // This will trigger re-renders
// //   const counterRef = useRef(0); // This will hold the counter without triggering re-renders
// //   const intervalRef = useRef(null); // To store the interval reference
// //   const invisibleRef = useRef(false); // To store the interval reference

// //   useEffect(() => {
// //     // Function to increment the counter reference
// //     const incrementCounter = () => {
// //       counterRef.current += 1; // Increment the counter in the ref
// //       console.log("Timer tick:", counterRef.current);
// //       setCounter(counterRef.current); // Update the state with the new counter value

// //       // apiChecksCall();



// //       try {

// //         const activeApiCall = async () => {
// //           const respData = await GetLastestsResults();
// //           const data = respData;

// //           console.log(" data ", data);
// //           console.log(" counter :  ", counter);

// //           // timerFunc();
// //           // handleBadgeVisibility();

// //           // invisibleRef.current = !invisibleRef.current;
// //           // setInvisible(invisibleRef.current);

// //           // handleBadgeVisibility();



// //           if (counterRef.current) {
// //             setInvisibleAndRef(true);
// //             // setInvisible(true);
// //           } else {
// //             setInvisibleAndRef(false);
// //             // setInvisible(false);
// //           }

// //           console.log("  invisibleRef.current : ", invisibleRef.current);

// //         }

// //         activeApiCall();

// //       }
// //       catch (error) {

// //         console.log("error : ", error);
// //       }

// //       // };

// //     };

// //     // Start interval only if it doesn't exist
// //     if (!intervalRef.current) {
// //       intervalRef.current = setInterval(incrementCounter, 5000); // Increment every 5 seconds
// //     }

// //     // Cleanup interval on component unmount
// //     return () => {
// //       if (intervalRef.current) {
// //         clearInterval(intervalRef.current); // Clear the interval
// //         intervalRef.current = null; // Reset the interval reference
// //       }
// //     };


// //   }, []); // Empty dependency array ensures this only runs once on mount


// //   // apiChecksCall();
// //   // setInterval(apiChecksCall, 20_000);

// //   return (
// //     <Box sx={{ color: 'action.active' }}>
// //       <Badge color="primary" variant="dot" invisible={invisible} >
// //         <ExecutionIcon />

// //       </Badge>

// //       <p>Counter: {counter}</p> {/* Display the counter */}
// //     </Box>
// //   )
// // }





export { ExecutionWithDotBadge };
