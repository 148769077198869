import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';

const GameOfLife = () => {
  const gridSize = 30;
  const maxGenerations = 50;

  // Initializing an empty grid
  const createEmptyGrid = () => Array.from({ length: gridSize }, () => Array.from({ length: gridSize }, () => 0));

  // Initializing a random grid
  const createRandomGrid = () => Array.from({ length: gridSize }, () => Array.from({ length: gridSize }, () => Math.random() >= 0.3 ? 0 : 1));

  const [grid, setGrid] = useState(createEmptyGrid());
  const [previousGrid, setPreviousGrid] = useState(createEmptyGrid());
  const [generation, setGeneration] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [showGrid, setShowGrid] = useState(true);


  // Function to calculate the next generation
  // const computeNextGen = useCallback((g) => {
  //   const nextGen = g.map(arr => [...arr]);
  //   for (let i = 0; i < gridSize; i++) {
  //     for (let j = 0; j < gridSize; j++) {
  //       let neighbors = 0;
  //       for (let x = -1; x < 2; x++) {
  //         for (let y = -1; y < 2; y++) {
  //           if (x === 0 && y === 0) continue;
  //           const ix = (i + x + gridSize) % gridSize;
  //           const jy = (j + y + gridSize) % gridSize;
  //           neighbors += g[ix][jy];
  //         }
  //       }

  //       if (neighbors < 2 || neighbors > 3) {
  //         nextGen[i][j] = 0;
  //       } else if (g[i][j] === 0 && neighbors === 3) {
  //         nextGen[i][j] = 1;
  //       }
  //     }
  //   }
  //   return nextGen;
  // }, [gridSize]);


  // Function to calculate the next generation
  const computeNextGen = useCallback((g) => {
    const nextGen = g.map(arr => [...arr]); // Clone the grid for immutability
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        let neighbors = 0;
        for (let x = -1; x <= 1; x++) {
          for (let y = -1; y <= 1; y++) {
            if (x === 0 && y === 0) continue; // Skip the cell itself
            const ix = i + x;
            const jy = j + y;
            if (ix >= 0 && ix < gridSize && jy >= 0 && jy < gridSize) {
              neighbors += g[ix][jy];
            }
          }
        }
  
        // Rules of Life
        if (neighbors < 2 || neighbors > 3) {
          nextGen[i][j] = 0;
        } else if (g[i][j] === 0 && neighbors === 3) {
          nextGen[i][j] = 1;
        }
      }
    }
    return nextGen;
  }, [gridSize]);
  

  // Toggle cells before starting the simulation
  // Toggle cells before starting the simulation
  const toggleCell = (i, j) => {
    const newGrid = [...grid];
    newGrid[i][j] = grid[i][j] ? 0 : 1;
    setGrid(newGrid);
  };

  useEffect(() => {
    let timer;
    if (isRunning && generation < maxGenerations) {
      timer = setTimeout(() => {
        setPreviousGrid(grid);
        setGrid(prevGrid => {
          const newGen = computeNextGen(prevGrid);
          return newGen;
        });
        setGeneration(prevGen => prevGen + 1);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [grid, generation, isRunning, computeNextGen]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div style={{ display: 'grid', gridTemplateColumns: `repeat(${gridSize}, 25px)` }}>
        {grid.map((rows, i) => rows.map((col, j) => (



          <div
              key={`${i}-${j}`}
              onClick={() => toggleCell(i, j)}

              style={{
                width: 25,
                height: 25,
                backgroundColor: grid[i][j] ?  'green'  : 'white',
                
                // backgroundColor: grid[i][j] ? (grid[i][j] !== previousGrid[i][j] ? 'green' : 'skyblue') : 'white',
                
                cursor: 'pointer',
                border: showGrid ? '1px solid gray' : 'none',
                // borderRadius: 12
              }}

            />

        )))}
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" sx={{ margin: 1 }} onClick={() => { setGrid(createEmptyGrid()); setPreviousGrid(createEmptyGrid()); setGeneration(0); setIsRunning(false); }}>Reset</Button>
        <Button variant="contained" sx={{ margin: 1 }} onClick={() => setIsRunning(true)}>Start Game</Button>
        <Button variant="contained" sx={{ margin: 1 }} onClick={() => setIsRunning(false)}>Pause Game</Button>
        <Button variant="contained" sx={{ margin: 1 }} onClick={() => { setGrid(createRandomGrid()); setPreviousGrid(createEmptyGrid()); setGeneration(0); setIsRunning(false); }}>Random Game</Button>
        <Button variant="contained" sx={{ margin: 1 }} onClick={() => setShowGrid(!showGrid)}>
  {showGrid ? 'Hide Grid' : 'Show Grid'}
</Button>
      </div>
      <div style={{ borderRadius: 25 ,paddingLeft: 14, paddingRight: 14,  backgroundColor: "gold", marginTop: 10}}>
      <h3 style={{ color : "black" }} >Generation: {generation}</h3>
      </div>
    </div>
  );
};

export default GameOfLife;







// import React, { useState, useEffect, useCallback } from 'react';
// import Button from '@mui/material/Button';


// const GameOfLife = () => {
//   const gridSize = 30; // 20x20 grid for a bigger game
//   const maxGenerations = 50;

//   // Initializing the grid
//   const createEmptyGrid = () => {
//     const rows = [];
//     for (let i = 0; i < gridSize; i++) {
//       rows.push(Array.from(Array(gridSize), () => 0));
//     }
//     return rows;
//   };

//   // Initializing the grid
//   const createRandomGrid = () => {
//     const rows = [];
//     for (let i = 0; i < gridSize; i++) {
//       rows.push(Array.from(Array(gridSize), () => Math.random() >= 0.3 ? 0 : 1));
//     }
//     return rows;
//   };

//   const [grid, setGrid] = useState(createEmptyGrid());
//   const [generation, setGeneration] = useState(0);
//   const [isRunning, setIsRunning] = useState(false);

//   // Function to calculate the next generation
//   const computeNextGen = (g) => {
//     const nextGen = g.map(arr => [...arr]); // Clone the grid for immutability
//     for (let i = 0; i < gridSize; i++) {
//       for (let j = 0; j < gridSize; j++) {
//         let neighbors = 0;
//         for (let x = -1; x < 2; x++) {
//           for (let y = -1; y < 2; y++) {
//             if (x === 0 && y === 0) continue; // Skip the cell itself
//             const ix = (i + x + gridSize) % gridSize; // Wrap around the grid edges
//             const jy = (j + y + gridSize) % gridSize;
//             neighbors += g[ix][jy];
//           }
//         }

//         // Rules of Life
//         if (neighbors < 2 || neighbors > 3) {
//           nextGen[i][j] = 0;
//         } else if (g[i][j] === 0 && neighbors === 3) {
//           nextGen[i][j] = 1;
//         }
//       }
//     }
//     return nextGen;
//   };

//   // Toggle cells before starting the simulation
//   const toggleCell = useCallback((i, j) => {
//     setGrid(prevGrid => {
//       const newGrid = [...prevGrid];
//       newGrid[i][j] = prevGrid[i][j] ? 0 : 1;
//       return newGrid;
//     });
//   }, []);
  

//   useEffect(() => {
//     let timer;
//     if (isRunning && generation < maxGenerations) {
//       timer = setTimeout(() => {
//         setGrid(g => computeNextGen(g));
//         setGeneration(g => g + 1);
//       }, 1000);
//     }

//     return () => clearTimeout(timer); // Cleanup the timeout on component unmount
//   }, [grid, generation, isRunning]);

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
//       <div style={{ display: 'grid', gridTemplateColumns: `repeat(${gridSize}, 25px)` }}>
//         {grid.map((rows, i) =>
//           rows.map((col, j) => (
//             <div
//               key={`${i}-${j}`}
//               onClick={() => toggleCell(i, j)}
//               style={{
//                 width: 25,
//                 height: 25,
//                 backgroundColor: grid[i][j] ? 'black' : 'white',
//                 border: '1px solid black',
//                 cursor: 'pointer'
//               }}
//             />
//           ))
//         )}
//       </div>
//       <div style={{ marginTop: '20px' }}>
//         <Button variant="contained" sx={{ margin: 1 }} onClick={() => { setGrid(createEmptyGrid()); setGeneration(0); setIsRunning(false); }}>Reset</Button>
//         <Button variant="contained" sx={{ margin: 1 }} onClick={() => setIsRunning(true)}>Start Game</Button>
//         <Button variant="contained" sx={{ margin: 1 }} onClick={() => setIsRunning(false)}>Pause Game</Button>
//         <Button variant="contained" sx={{ margin: 1 }} onClick={() => { setGrid(createRandomGrid()); setGeneration(0); setIsRunning(false); }}>Random Game</Button>
//       </div>
//       <p>Generation: {generation}</p>
//     </div>
//   );
// };

// export default GameOfLife;
