


import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { GetHeadersContentAuth } from '../../Shared/AuthShared';
import axios from 'axios';



const GetLastestsResults = async () => {

    const url = `${LeaBaseUrl}/api/cmd_results_max_ts`;

    console.log(" GetLastestsResults ");

    const config = {
        headers: GetHeadersContentAuth()
    };

    try {
        const resp = await axios.get(url, config);
        return resp.data;
    } catch (err) {
        return Promise.reject(err);
    }
}



export { GetLastestsResults };