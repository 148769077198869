

const HelloFunc = () => {
    // console.log("%c ** Hello !@#$", 'background: #222; color: #bada55');
    console.log("%c ** Hello !@#$", 'background: #777; color: #bada55');
}

// const FunctionRefresh = (action = HelloFunc) => {
//     action();
// }

class FunctionChangeClass {
    #action = HelloFunc;

    setNewAction(newActionFunction) {
        if (typeof newActionFunction === 'function') {
            this.#action = newActionFunction;
        } else {
            throw new Error('newActionFunction must be a function');
        }
    }

    reset() {
        this.#action = HelloFunc;
    }


    ActiveAction() {
        this.#action();
    }
}


const functionChangeElement = new FunctionChangeClass();

export { functionChangeElement };