import React from 'react'
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent } from '@mui/material';

import TablePagination from '@mui/material/TablePagination';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';

import { GetUsersSorting } from './UsersRequests.js';

import { functionChangeElement } from '../../Shared/FunctionChange';


function Row(props) {
  const { row } = props;

  return (

    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, padding: 0, margin: 0 }}   >
        <TableCell sx={{ padding: 0 }}>
          <Box sx={{ width: 32 }} > </Box>
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }}  >
          <Box >
            {row.name}
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{row.roles} </TableCell>
      </TableRow>
    </React.Fragment>

  );
}


const transformedDataElementAction = (item) => {
  console.log("User : item : ", item)


  const name = item.user_name;
  const roles = item.role_name;
  const id = item.user_id;

  // console.log("User : item : roles ", roles)

  const transformedObject = {
    id: id,
    key: id,
    name: name,
    roles: roles,
  };

  return transformedObject;
}

const transformedDataArrAction = (usersData) => {

  if (!usersData) { return []; }

  const transformedDataAry = usersData.map(item => {
    return transformedDataElementAction(item);
  });

  return transformedDataAry;
}

const colsHeaderNames = [
  { title: "Name", fieldName: "user_name" },
  { title: "Roles", fieldName: "role_name" }, // Sort -> it's not working for now i turn it off , later if needed will be fixed !
];

const Users = () => {

  functionChangeElement.setNewAction(() => {

    const offset = page;
    const limit = rowsPerPage;

    console.log("%c Users! ", "background: pink ;");
    handleClickedHeader(-1, offset, limit);
  });


  const [totalCount, setTotalCount] = useState(20);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {

    const offset = newPage;
    const limit = rowsPerPage;

    setPage(offset);

    handleClickedHeader(-1, offset, limit);
  };


  const handleChangeRowsPerPage = (event) => {

    const limit = +event.target.value;
    const offset = 0;

    setRowsPerPage(limit);
    setPage(offset);

    handleClickedHeader(-1, offset, limit);
  };

  const [dataUser, setDataUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const [colsIndex, setColsIndex] = useState(-1);
  const [reverseSort, setReverseSort] = useState(false);


  const [lastSortKey, setLastSortKey] = useState(colsHeaderNames[0].fieldName);

  const checkReverse = (index) => {
    let reverse = reverseSort;

    if (index === colsIndex) {
      reverse = !reverse;
    } else {
      reverse = false;
    }

    setReverseSort(reverse);
    return reverse;
  }

  const handleClickedHeader = (index, offset, limit) => {

    const reverse = index > -1 ? checkReverse(index) : reverseSort;
    let key = lastSortKey; // "active";

    setColsIndex(index);

    // name lastSeen distribution version
    if (0 < index && index < colsHeaderNames.length) {
      key = colsHeaderNames[index].fieldName;
      setLastSortKey(key);
    }

    const totalOffset = offset * limit;
    apiCall(key, reverse, totalOffset, limit);
  }

  const apiCall = async (key, reverse, totalOffset, limit) => {

    setIsLoading(true);

    try {

      const usersDataAndCount = await GetUsersSorting(key, reverse, totalOffset, limit);

      const usersData = usersDataAndCount.data;
      const usersCount = usersDataAndCount.count;

      const transformedDataAry = transformedDataArrAction(usersData);

      setTotalCount(usersCount)
      setDataUser(transformedDataAry);
    }
    catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status < 400) { return; }

      // props.logout();
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(() => {

    const offset = page;
    const limit = rowsPerPage;

    const indexOfName = 0;

    handleClickedHeader(indexOfName, offset, limit);

  }, []);


  return (
    <Card>
      <CardContent>

        {isLoading ?

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GradientCircularProgress ></GradientCircularProgress>
          </Box> :
          <>


            <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }} size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 10, padding: 0 }}>
                      <Box sx={{ width: 10 }}>
                      </Box>
                    </TableCell>

                    {colsHeaderNames.map((colItem, index) => (
                      <TableCell
                        // align={colItem.align}
                        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={() => handleClickedHeader(index, page, rowsPerPage)}

                      >

                        <Box sx={{ display: 'flex' }}>

                          <Box>
                            {colItem.title}
                          </Box>
                          <Box sx={{ justifyItems: 'flex-end' }} >

                            {
                              colsIndex >= 0 && colsIndex === index ?
                                (reverseSort ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                                : null


                            }

                          </Box>
                        </Box>
                      </TableCell>
                    ))}

                  </TableRow>
                </TableHead>
                <TableBody >
                  {dataUser.map((row) => (
                    <Row key={row.id} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </>}


      </CardContent>
    </Card>
  );
}


export default Users;