import React from 'react'
import { AppBar, Box, Button, CssBaseline, Toolbar, Typography } from '@mui/material'
import logo from './assets/logo.svg'
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import { useNavigate } from "react-router-dom";

import SyncIcon from '@mui/icons-material/Sync';

import { functionChangeElement } from './Shared/FunctionChange';

export default function TopHeader({ toggle, onLogOut, isOpen, isLoggedIn }) {
  const nav = useNavigate();
  const handleRefresh = () => {
    functionChangeElement.ActiveAction();
    console.log(" clicked sync from header")
  }

  functionChangeElement.reset()

  return (
    // todo
    // design the topHeader to look better and scrolled with the page 
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: '#022E57' }}  >
        <Toolbar>
          <Typography variant='h6' noWrap component={'div'} sx={{ flexGrow: 1, textAlign: 'left' }}>
            <img alt="Company Logo" src={logo} onClick={() => { nav('/'); }} style={{ cursor: "pointer" }} />
          </Typography>

          <Button variant='text' sx={{ color: 'white', display: isLoggedIn ? 'inline-flex' : 'none' }} onClick={handleRefresh}>
            <SyncIcon />
          </Button>

          <Button variant='text' sx={{ color: 'white' }}>
            <PersonOutlineIcon />
          </Button>
          <Button variant='text' sx={{ color: 'white' }} onClick={onLogOut}>
            <LogoutSharpIcon />
          </Button>
        </Toolbar>
      </AppBar>


    </Box>
  )
}
