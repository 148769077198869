import React, { useEffect, useState } from 'react';
import { GetClients } from '../../Shared/HttpRequests';
import PieChart from './PieChart';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import BasicBars from './BarChart';
import { functionChangeElement } from '../../Shared/FunctionChange';



export default function Dashboard({ isLoggedIn }) {

    functionChangeElement.setNewAction(() => {
        console.log("%c Dashboard ! ", "background: white ; color: black;");
        apiCallAndDataTransform();

    });

    const [totalClientHosts, setTotalCleintHosts] = useState(0);
    const [totalVerifiedClients, setTotalVerifiedClients] = useState(0);
    const [totalUnVerifiedClients, setTotalUnVerifiedClients] = useState(0);

    const [dataClients, setDataClients] = useState({});

    const apiCallAndDataTransform = () => {

        const limit = 1000;
        const offset = 0;

        GetClients(offset, limit).then(response => {

            const numberOfTotalClientHosts = response.length;
            let verifiedCounts = 0;

            response.forEach(client => {
                if (client.verified === true) {
                    verifiedCounts++;
                }
            })

            setTotalCleintHosts(numberOfTotalClientHosts);
            setTotalVerifiedClients(verifiedCounts);
            setTotalUnVerifiedClients(numberOfTotalClientHosts - verifiedCounts);

            // console.log(" totalVerifiedClients :  ", totalVerifiedClients);
            // console.log(" totalUnVerifiedClients :  ", totalUnVerifiedClients);

            setDataClients(response)
        })
            .catch(error => {
                console.error("Error fetching data:", error);
            });

    }

    useEffect(() => {

        apiCallAndDataTransform();

    }, [])


    return (
        <Grid container spaing={3}>
            <Grid xs={4}>
                <PieChart totalVerifiedClients={totalVerifiedClients} totalUnVerifiedClients={totalUnVerifiedClients}   ></PieChart>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={4}>
                <BasicBars dataClients={dataClients}  ></BasicBars>
            </Grid>
        </Grid>
    )
}
