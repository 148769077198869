import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

import Box from '@mui/material/Box';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';

import { activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';



function SelectTimePeriod(props) {

    const [durations, setDurations] = React.useState(3);

    // console.log("init durations : ", durations);

    const handleChange = (event) => {
        setDurations(event.target.value);
        console.log("durations : ", event.target.value);

        props.getDurationsAction(event.target.value);
    };

    return (<>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Durations</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={durations}
                label="Durations"
                onChange={handleChange}
            >
                <MenuItem value={3}>3 Days</MenuItem>
                <MenuItem value={7}>7 Days</MenuItem>
                <MenuItem value={30}>Month</MenuItem>
            </Select>
        </FormControl>
    </>)
}


export default function BasicBars(props) {

    const dataClients = (props.dataClients && props.dataClients.length > 0) ? props.dataClients : [];
    const countsMap = MapOfVerifiedDay(dataClients);

    const [durations, setDurations] = React.useState(3);

    const getDurationsAction = (time) => {
        setDurations(time);
        // console.log("Line graph => durations : ", time);
    }

    const lastNDays = getLastNDays(durations);
    const executeDays = [];

    const arrVerified = [];
    const arrUnverified = [];

    lastNDays.forEach(day => {

        const newDay = {
            date: day,
            verified: 0,
            unverified: 0
        };

        executeDays.push(newDay)
        const dayCountFromMap = countsMap[day];

        if (dayCountFromMap) {
            newDay.verified = dayCountFromMap.verified;
            newDay.unverified = dayCountFromMap.unverified;
        }

        arrVerified.push(newDay.verified);
        arrUnverified.push(newDay.unverified);

    });

    const countsArray = executeDays;
    const datesArray = countsArray.map(item => new Date(item.date));
    const countsArrayNumbers = countsArray.map(item => item.verified);

    const [displayData, setDisplayData] = React.useState(false);

    return (

        <Box sx={{ width: 700, height: 0 }} >
            <Box sx={{ display: 'flex', justifyContent: "center" }} >
                <Box>

                    <h2> Active Hosts </h2>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: "center", marginTop: 2 }} >
                <SelectTimePeriod getDurationsAction={getDurationsAction} ></SelectTimePeriod>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: "center", marginTop: 0 }} >

                <BarChart
                    xAxis={[{ scaleType: 'band', data: lastNDays }]}
                    series={[
                        // { data: [3, 1, 5], color: activeBarColor, label: "active" },
                        // { data: [2, 4, 1], color: notActiveBarColor, label: "not active" }

                        { data: arrVerified, color: activeBarColor, label: "active" },
                        { data: arrUnverified, color: notActiveBarColor, label: "not active" }
                    ]}

                    width={700}
                    height={350}
                />

            </Box>

            {displayData ?
                <Box sx={{ margin: 6 }}>

                    <Box>
                        ==
                        <p>
                            {JSON.stringify(datesArray)}
                        </p>
                        --

                        ==
                        <p>
                            {countsArrayNumbers}
                        </p>
                        --

                        <p>
                            Title:
                            {"stringHosts"}
                        </p>

                        <Box>
                            <h2>New method to calculate </h2>
                            <p>
                                {JSON.stringify(countsArray, null, 4)}
                            </p>
                        </Box>
                    </Box>

                    ~~~~~
                    <p>
                        {JSON.stringify(lastNDays)}
                    </p>
                    ~~~~~

                    <Box>
                        arrVerified : {JSON.stringify(arrVerified)}
                    </Box>
                    <Box>
                        arrUnverified : {JSON.stringify(arrUnverified)}
                    </Box>

                    <Box sx={{ height: 60 }}></Box>



                </Box>

                : null
            }


        </Box>



    );
}




export function MapOfVerifiedDay(data) {

    const formatDate = (date) => date.toISOString().split('T')[0];

    // Create a map to hold the counts
    const countsMap = {};

    // Process the data
    data.forEach(item => {
        const date = formatDate(new Date(item.ts));
        if (!countsMap[date]) {
            countsMap[date] = { verified: 0, unverified: 0 };
        }
        if (item.verified) {
            countsMap[date].verified++;
        } else {
            countsMap[date].unverified++;
        }

        // console.log(" date : ", date);
    });

    // console.log("Counts map : ", countsMap);

    return countsMap;
}

function getLastNDays(n) {
    const today = new Date();
    return Array.from({ length: n }, (_, i) => {
        const date = new Date();
        date.setDate(today.getDate() - i);
        return date.toISOString().split('T')[0];
    }).reverse();
}