
import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { GetHeadersContentAuth } from '../../Shared/AuthShared';
import { AuthAndCountHeaders, GetDataAndCountResponse } from '../../Shared/UtillsJsFunctions';
import axios from 'axios';



const GetClientsSorting = async (sortField, orderAsc = true, offset = 0, limit = 100) => {

    const sortOrder = orderAsc ? "asc" : "desc";
    const url = `${LeaBaseUrl}/api/clients_verified_status?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}

const GetSearchCompliancyElements = (searchParam, sortField = "name") => {

    const sortOrder = "asc";
    const url = `${LeaBaseUrl}/api/clients_verified?id=eq.${searchParam}&order=${sortField}.${sortOrder}`;

    const config = {
        headers: GetHeadersContentAuth()
    };

    return axios
        .get(url, config)
        .then(resp => {
            const clientsDataObj = resp.data;
            return clientsDataObj;
        })
        .catch(
            err => {
                return Promise.reject(err);
            }
        )
}





export { GetClientsSorting };
export { GetSearchCompliancyElements };

